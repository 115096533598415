import http from './httpService'
import Logger from './logService'
import apiHelper from 'Utils/apiHelper';

export async function getAllPayments(){
    const { data: response } = await http
    .get("/payments")
    .then(res => {
      return res;
    })
    .catch(err => {
      Logger.error(err);
    });

  return response;
}
export async function requestPaypal(subscription){
    const {data: response }  = await http
    .get("/paypal/request/"+subscription)
    .then( res => res)
    .catch(err => Logger.error(err));

    return response;
}

export async function requestPin(information){
    const {data: response }  = await http
    .post("/pin/pay", information)
    .then( res => res)
    .catch(err => {
        Logger.error(err);
        return apiHelper.badResponse(null, false, "no response from server")
    });
    return response;
}

export default {
    requestPaypal, requestPin, getAllPayments
};