import React from "react";
import { Link } from "react-router-dom";
const NavBar = ({ user }) => {
  return (
    <nav
      className="navbar navbar-expand-md navbar-dark fixed-top"
      style={{ background: "#ffffff" }}
    >
      <button
        className="navbar-toggler navbar-toggler-right"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="ion-grid icon-sm" />
      </button>
      <a className="navbar-brand hero-heading" href="/home">
        {" "}
        <img
          src="../template/img/lingmo_live_logo.png"
          width="120"
          height="64"
          alt=""
        />
      </a>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item mr-3">
            <Link to="/home" className="nav-link page-scroll">
              <font color="#1a6bb4">Home</font>{" "}
              <span className="sr-only">(current)</span>
            </Link>
          </li>
          <li className="nav-item mr-3">
            <Link to="/home/packages" className="nav-link page-scroll">
              <font color="#1a6bb4">Packages</font>{" "}
            </Link>
          </li>
          {!user && (
            <React.Fragment>
              <li className="nav-item mr-3">
                <Link to="/home/register" className="nav-link page-scroll">
                  <font color="#1a6bb4">Sign up</font>{" "}
                </Link>
              </li>
              <li className="nav-item mr-3">
                <Link to="/home/login" className="nav-link page-scroll">
                  <font color="#1a6bb4">Login</font>{" "}
                </Link>
              </li>
            </React.Fragment>
          )}

          {user && (
            <React.Fragment>
              <li className="nav-item mr-3">
                <Link to="/portal" className="nav-link page-scroll">
                  <font color="#1a6bb4">Live chat portal</font>{" "}
                </Link>
              </li>
              {/* <li className="nav-item mr-3">
                <Link to="/home/account" className="nav-link page-scroll">
                  <font color="#1a6bb4">My account</font>{" "}
                </Link>
              </li> */}
              <li className="nav-item mr-3">
                <Link to="/home/logout" className="nav-link page-scroll">
                  <font color="#bb4444">Logout</font>{" "}
                </Link>
              </li>
            </React.Fragment>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
