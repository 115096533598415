/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import custService from "Services/customerService";
import Pagination from "Components/Common/pagination";
import { toast } from "react-toastify";
import { paginate } from "Utils/paginate";
import ListGroup from "Components/Common/listGroup";
import CustomerTable from "./Components/customer-table";
import Logger from "Services/logService";
import { PageHeading } from "../portalLayout";
import Registration from "Home/registration";
import CustomerForm from "./Components/customer-form";
import Loader from "Components/Common/loader";
import WebsiteForm from "./Components/website-form";

//Modal.setAppElement("#newCustomerModal");
class AdminCustomers extends Component {
  state = {
    loading: true,
    customers: [],
    subscriptionTypes: [],
    currentPage: 1,
    pageSize: 10,
    sortColumn: { path: "email", order: "asc" },
    selectedSub: { title: "All" },
    searchKeywords: "",

    editedCustomerId: 0,
    customerToEdit: null,
    cusModalIsOpen: false,
    webModalIsOpen: false
  };

  setShow = (modalType, show) => {
    if (modalType === "customer") this.setState({ cusModalIsOpen: show });
    if (modalType === "website") this.setState({ webModalIsOpen: show });
  };

  openModal = modalType => {
    this.setShow(modalType, true);
  };
  closeModal = modalType => {
    this.setShow(modalType, false);

    this.setState({ customerToEdit: null });
    this.setState({ websiteToEdit: null });

    this.componentDidMount();
  };

  async componentDidMount() {
    try {
      this.setState({ loading: true, customerToEdit: null });
      const data = await this.loadCustomers();
      const subTypes = await this.loadSubscriptionTypes();

      this.setState({ customers: data, subscriptionTypes: subTypes });
    } catch (ex) {
      toast.error(ex.message);
    } finally {
      this.setState({ loading: false });
    }
  }

  async loadCustomers() {
    const { data, status, message } = await custService.getAll();

    if (!status) toast.error(message);
    return data;
  }

  async loadSubscriptionTypes() {
    const { data, status, message } = await custService.getSubscriptionTypes();
    if (!status) toast.error(message);
    const sTypes = [];
    sTypes.push({ title: "All" }, ...data);

    return sTypes;
  }

  async updateCustomer(customer) {
    const { data } = await custService.update(customer);


    if (data.status) {
      return true;
    } else {
      return false;
    }
  }

  handleEdit = async customer => {
    this.setState({ customerToEdit: customer });
    this.openModal("customer");
  };

  handleAddWebsite = async customer => {
    this.setState({
      customerToEdit: customer,
      editedCustomerId: customer.customerId
    });
    this.openModal("website");
  };

  handleViewWebsites = async customer => {
    this.props.history.push(
      "/portal/admin/websites?search=" + customer.username
    );
  };

  handleViewAgents = customer => {
    this.setState({ recordToEdit: customer });

    this.props.history.push(
      `/portal/admin/agents?customerId=${customer.customerId}`
    );
  };
  handleDelete = async customer => {
    const originalData = this.state.customers;

    const customers = this.state.customers.filter(
      c => c.customerId !== customer.customerId
    );
    this.setState({ customers });

    const { message, status } = await custService.remove(customer.customerId);
    if (status) toast.success(message);
    else {
      Logger.error(message);
      this.setState({ customers: originalData });
    }
  };

  handleActivity = async customer => {
    //toast("Active link clicked");
    const originalCustomers = [...this.state.customers];
    const customers = [...this.state.customers];
    const index = customers.indexOf(customer);
    customers[index] = { ...customers[index] };
    customers[index].active = !customers[index].active;

    await this.updateCustomer(customers[index]).then(result => {
      if (result) {
        toast.success("Selected customer's record updated");
        this.setState({ customers });
      } else {
        toast.error("Failed to update customer");
        this.setState({ customers: originalCustomers });
      }
    });
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSubscriptionSelect = sub => {
    this.setState({ selectedSub: sub, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleSearch = event => {
    //handleSearch
    this.setState({ searchKeywords: event.target.value });
  };

  OpenNewCustomerPage = () =>
    toast(<Registration />, {
      closeButton: true,
      autoClose: false,
      closeOnClick: false,
      position: "top-center",
      draggable: false
      //onOpen: ({ foo }) => window.alert("I counted to infinity once then.."),
      //onClose: ({ foo }) => window.alert("I counted to infinity twice")
    });

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedSub,
      customers: allCustomers,
      searchKeywords
    } = this.state;

    let filtered =
      selectedSub && selectedSub.subscriptionId
        ? allCustomers.filter(
            m =>
              m.activeSubscription &&
              m.activeSubscription.subscriptionId === selectedSub.subscriptionId
          )
        : allCustomers;

    filtered =
      searchKeywords.length > 0
        ? filtered.filter(
            m =>
              (m.customerName &&
                m.customerName
                  .toLowerCase()
                  .indexOf(searchKeywords.toLowerCase()) !== -1) ||
              (m.username &&
                m.username
                  .toLowerCase()
                  .indexOf(searchKeywords.toLowerCase()) !== -1) ||
              (m.phone1 &&
                m.phone1.toLowerCase().indexOf(searchKeywords.toLowerCase()) !==
                  -1)
          )
        : filtered;

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const customers = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: customers };
  };

  render() {
    if (this.state.loading) return <Loader />;

    const { length: count } = this.state.customers;
    const {
      pageSize,
      currentPage,
      sortColumn,
      subscriptionTypes,
      selectedSub
    } = this.state;

    if (count === 0) return <p>There are no customers in database.</p>;

    const { totalCount, data: customers } = this.getPagedData();

    return (
      <React.Fragment>
        <PageHeading heading="Customers" />
        <div className="row">
          <div className="col-2">
            <ListGroup
              items={subscriptionTypes}
              textProperty="title"
              valueProperty="subscriptionId"
              selectedItem={selectedSub}
              onItemSelect={this.handleSubscriptionSelect}
            />
          </div>
          <div className="col-10">
            <div className="flex-row">
              <div className="flex-col">
                <button
                  variant="primary"
                  onClick={() => this.openModal("customer")}
                >
                  Add new customer
                </button>
              </div>
              <div className="flex-col-right">
                <input
                  value={this.state.searchKeywords}
                  name="txtSearch"
                  type="text"
                  onChange={this.handleSearch}
                ></input>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p>Showing {totalCount} customer accounts.</p>
                <CustomerTable
                  customers={customers}
                  sortColumn={sortColumn}
                  onActive={this.handleActivity}
                  onDelete={this.handleDelete}
                  onSort={this.handleSort}
                  onEdit={this.handleEdit}
                  onAddWebsite={this.handleAddWebsite}
                  onViewWebsites={this.handleViewWebsites}
                  onViewAgents={this.handleViewAgents}
                />
                <Pagination
                  itemsCount={totalCount}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  onPageChange={this.handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>

        <Modal
          showoverlay={false}
          size="lg"
          show={this.state.cusModalIsOpen}
          onHide={() => this.closeModal("customer")}
          dialogClassName="modal-dialog"
        >
          <Modal.Header closeButton>
            <Modal.Title>Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CustomerForm
              customerToEdit={this.state.customerToEdit}
              isAdmin={true}
              onClose={() => this.closeModal("customer")}
            />
          </Modal.Body>
        </Modal>

        <Modal
          showoverlay={false}
          size="lg"
          show={this.state.webModalIsOpen}
          onHide={() => this.closeModal("website")}
          dialogClassName="modal-dialog"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add new website</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <WebsiteForm
              customerId={this.state.editedCustomerId}
              recordToEdit={null}
              onClose={() => this.closeModal("website")}
            />
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default AdminCustomers;
