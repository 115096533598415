import React from "react";

function Loader() {
  return (
    <div className="loader center fa-3x">
      {/* <i className="fa fa-cog fa-spin" /> */}
      <i className="fas fa-spinner fa-pulse"></i>
    </div>
  );
}

export default Loader;
