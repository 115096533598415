import React, { Component } from "react";
import { ToastContainer } from "react-toastify"; //for toast alerts
import { Switch, Route, Redirect } from "react-router-dom"; //for navigation
import { SideBar, TopBar, Footer } from "./portalLayout";
import Dashboard from "./dashboard";
import Logout from "Portal/logout";
import authService from "Services/authService";
import "react-toastify/dist/ReactToastify.css"; //toast css
import "Template/css/sb-admin-2.min.css";
import "./portal.css";
import { AdminRoute, ProtectedRoute } from "Components/Common/routes";
import AdminCustomers from "./Admin/customers";
import AdminWebsites from "./Admin/websites";
import AdminAgents from "./Admin/agents";
import AdminReports from "./Admin/reports";
import AdminChats from "./Admin/chats";

import CustomerSubscriptions from "./Customers/subscriptions";
import CustomerWebsites from "./Customers/websites";
import CustomerAgents from "./Customers/agents";
import CustomerReports from "./Customers/reports";
import CustomerChats from "./Customers/chats";
import AdminPayments from "./Admin/payments";
import customerService from "Services/customerService";
import CustomerProfile from "./Customers/profile";
import CustomerChangePassword from "./Customers/change-password";
import CustomerNotifications from "./Customers/notifications";
import CustomerAlerts from "./Customers/alerts";

class PortalApp extends Component {
  state = {
    user: {},
    customer: {}
  };

  async componentDidMount() {
    const user = await authService.getCurrentUser();

    if (user) {
      const { data: customer } = await customerService.get(user.id);
      this.setState({ user, customer });
    }
  }

  render() {
    const { user, customer } = this.state;
    return (
      <React.Fragment>
        <ToastContainer
          autoClose={2000}
          newestOnTop={true}
          style={{ width: "600px", height: "100%" }}
        />
        {/*<!-- Page Wrapper -->*/}
        <div id="wrapper">
          <SideBar user={user} customer={customer} />

          {/*<!-- Content Wrapper -->*/}
          <div id="content-wrapper" className="d-flex flex-column">
            {/*<!-- Main Content -->*/}
            <div id="content">
              <TopBar user={user} customer={customer} />

              {/*<!-- Begin Page Content -->*/}
              <div className="container-fluid">
                <Switch>
                  <AdminRoute
                    path="/portal/admin/customers"
                    exact
                    component={AdminCustomers}
                  />
                  <AdminRoute
                    path="/portal/admin/websites"
                    exact
                    component={AdminWebsites}
                  />
                  <AdminRoute
                    path="/portal/admin/agents"
                    exact
                    component={AdminAgents}
                  />
                  <AdminRoute
                    path="/portal/admin/chats"
                    exact
                    component={AdminChats}
                  />
                  <AdminRoute
                    path="/portal/admin/reports"
                    exact
                    component={AdminReports}
                  />
                  <ProtectedRoute
                    path="/portal/admin/payments"
                    exact
                    component={AdminPayments}
                  />
                  <ProtectedRoute
                    path="/portal/customers/subscriptions"
                    exact
                    component={CustomerSubscriptions}
                  />
                  <ProtectedRoute
                    path="/portal/customers/websites"
                    exact
                    component={CustomerWebsites}
                  />
                  <ProtectedRoute
                    path="/portal/customers/agents"
                    exact
                    component={CustomerAgents}
                  />
                  <ProtectedRoute
                    path="/portal/customers/chats"
                    exact
                    component={CustomerChats}
                  />
                  <ProtectedRoute
                    path="/portal/customers/reports"
                    exact
                    component={CustomerReports}
                  />
                  <ProtectedRoute
                    path="/portal/customers/payments"
                    exact
                    component={CustomerReports}
                  />

                  <ProtectedRoute
                    path="/portal/customers/profile"
                    exact
                    component={CustomerProfile}
                  />

                  <ProtectedRoute
                    path="/portal/customers/change-password"
                    exact
                    component={CustomerChangePassword}
                  />

                  <ProtectedRoute
                    path="/portal/customers/notifications"
                    exact
                    component={CustomerNotifications}
                  />

                  <ProtectedRoute
                    path="/portal/customers/alerts"
                    exact
                    component={CustomerAlerts}
                  />

                  <Route path="/portal/logout" exact component={Logout} />
                  <ProtectedRoute
                    path="/portal/dashboard"
                    component={Dashboard}
                  />
                  <Route path="/portal" component={Dashboard} />
                  {/*
        <Route
          path="/products"
          render={props => <Products sortBy="newest" {...props} />}
        />
        <Route path="/posts/:year?/:month?" component={Posts} />
        <Route path="/admin" component={Dashboard} />
        <Redirect from="/messages" to="/posts" /> */}
                  {/* 
                <Route path="/home/access-denied" Redirect="" />
                <Route path="/home/not-found"  Redirect="" /> */}
                  <Redirect to="/not-found" />
                </Switch>
              </div>
              {/*<!-- /.container-fluid -->*/}
            </div>
            {/*<!-- End of Main Content -->*/}

            <Footer />
          </div>
          {/*<!-- End of Content Wrapper -->*/}
        </div>
        {/*<!-- End of Page Wrapper -->*/}

        {/*<!-- Scroll to Top Button-->*/}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
        {/*
      <LogoutModalSection /> */}
      </React.Fragment>
    );
  }
}

export default PortalApp;
