import React, { Component } from "react";
import Table from "Components/Common/table";
import Moment from "react-moment";

class PaymentTable extends Component {
  columns = [
    { path: "paymentId", label: "Payment Id" },
    { path: "method", label: "Payment Method" },
    { path: "subscription.subscriptionName", label: "Subscription" },
    { path: "amount", label: "Amount" },
    { path: "respondAt", label: "Subscribed Date",
      content: payment => (
        <Moment format="YYYY/MM/DD HH:mm">{payment.respondAt}</Moment>
      )
    }
  ];

  render() {
    const { payments, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={payments}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default PaymentTable;
