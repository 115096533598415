import Raven from "raven-js";
import { toast } from "react-toastify";
//import * as Sentry from "@sentry/browser";
toast.configure();
function init() {
  // Sentry.init({
  //   dsn: "https://96cf26a2696d437d915a1443ef689c9b@sentry.io/1545576"
  // });

  Raven.config("https://96cf26a2696d437d915a1443ef689c9b@sentry.io/1545576", {
    release: "1-0-0",
    environment: "development-test"
  }).install();
}

//this function is requred for Raven to register errorts to Sentry, so don't remove it
function error(msg) {
  //disable Raven + sentry error logging for the time being
  //Raven.captureException(error);
  console.error(msg);
  toast.error(msg);
}

function log(msg) {
  console.log(msg);
  toast(msg);
}

function info(msg) {
  console.info(msg);
  toast.info(msg);
}

function success(msg) {
  console.log(msg);
  toast.success(msg);
}

export default {
  init,
  log,
  info,
  error,
  success
};
