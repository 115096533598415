import http from "./httpService";

export async function getSummary(customerId) {
    const { data: response } = await http.get(`/analytics/summary/`+customerId);
    return response;
  }
export async function getWebsiteSummary(customerId) {
  const { data: response } = await http.get(`/analytics/summaryWebsite/`+customerId);
  return response;
}
export async function getAgentSummary(customerId) {
  const { data: response } = await http.get(`/analytics/summaryAgent/`+customerId);
  return response;
}

export default {
    getSummary
};
  
  