import React from "react";

const ActiveSubscription = ({ subscription }) => {

  return (
    <React.Fragment>
      <h3>
        Your current subscription is {" "}
        {subscription ? subscription.title : "No subscription"}
      </h3>
      <p>
        To upgrade your subscription please go to 
        { " " } <a href="/portal/customers/subscriptions?tab=Upgrade">Upgrade</a> { " " }
         tab
      </p>
    </React.Fragment>
  );
};

export default ActiveSubscription;
