import React, { Component } from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import PortalApp from "Portal/portalApp";
import HomeApp from "Home/homeApp";
import { ProtectedRoute } from "Components/Common/routes";

class App extends Component {
  state = {};
  componentDidMount() {}
  render() {
    const hist = createBrowserHistory();

    // const user = auth.getCurrentUser();
    return (
      <Router history={hist}>
        <Switch>
\          <ProtectedRoute path="/portal" component={PortalApp} />
          <Route key="k1" path="/home" render={props => <HomeApp />} />
          <Redirect key="k2" to="/home" />
        </Switch>
      </Router>
    );
  }
}

export default App;
