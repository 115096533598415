import React from "react";

const Checkbox = ({ name, label, error, value, ...rest }) => {
  return (
    <React.Fragment>
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <input type="checkbox" name={name} checked={value === true} {...rest} />
        {error && (
          <div className="alert alert-danger login-control">{error}</div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Checkbox;
