import React, { Component } from 'react';
import { MonthlyAgents, AgentLanguages } from './Charts/AgentsCharts';
import { WebsiteLanguages, MonthlyWebsites } from './Charts/WebsiteCharts';
import { getCurrentUser } from "Services/authService";
import { toast } from "react-toastify";
import {getWebsiteSummary, getAgentSummary} from 'Services/analyticsService';

class WebsiteReport extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            monthyWebsites:[['Months', 'Website1', 'Website2'],
                            ] ,
            websiteLanguages:[['Langauges', 'Website1', 'Website2'],
                            ] ,

        };
    }
    async componentDidMount(){
        try{
          const user = await getCurrentUser();
          
          if(user){
            const data = await getWebsiteSummary(user.id);

            this.setState({
                monthyWebsites: data.data.monthlyChats.data,
                websiteLanguages: data.data.languagesUsed.data
            });  
          }
        } catch (ex) {
          toast.error(ex.message);
        }
      }
    
    render() { 
        return ( <React.Fragment>
            <div className="row">
                <MonthlyWebsites 
                    data={this.state.monthyWebsites}
                    size={8}
                    title="Monthly Tickets"
                />
                <WebsiteLanguages
                    data={this.state.websiteLanguages}
                    size={4}
                    title="Website Languages"
                />
            </div>
        </React.Fragment> );
    }
}

class AgentsReport extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            monthlyAgents:[['Month', 'Agent 1', 'Agent 2'],
                            ],
            agentLanguages:[['Langauges', 'Agent 1', 'Agent 2'],
                           ] ,
         }
    }
    async componentDidMount(){
        try{
          const user = await getCurrentUser();
          
          if(user){
            const data = await getAgentSummary(user.id);
            this.setState({
                monthlyAgents: data.data.monthlyChats.data,
                agentLanguages: data.data.languagesUsed.data
            });  
          }
        } catch (ex) {
          toast.error(ex.message);
        }
      }
    
    render() { 
        return ( <React.Fragment>
            <div className="row">
                <MonthlyAgents 
                    data={this.state.monthlyAgents}
                    size={8}
                    title="Monthly Tickets"
                />
                <AgentLanguages 
                    data={this.state.agentLanguages}
                    size={4}
                    title="Agents Languages"
                />
            </div>
        </React.Fragment> );
    }
}

 
export { WebsiteReport, AgentsReport};