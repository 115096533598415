import React, { Component } from 'react';
import Chart from "react-google-charts";

class PieChart extends Component {
    pieOptions = {
        title: "",
        is3D: true,
        pieHole: 0.6,
        slices: [
            {
                color: "#1d76bc"
            },
            {
                color: "#636a68"
            },
            {
                color: "#007fad"
            },
            {
                color: "#e9a227"
            }
        ],
        legend: {
            position: "bottom",
            alignment: "center",
            textStyle: {
                color: "233238",
                fontSize: 14
            }
        },
        tooltip: {
            showColorCode: true
        },
        chartArea: {
            left: 0,
            top: 0,
            width: "100%",
            height: "80%"
        },
        fontName: "Roboto"
    };
    state = {
        chartImageURI: ""
    };
    render() {
        return (
            <React.Fragment>
                <div className={`col-xl-${this.props.size} col-lg-${this.props.size}`}>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 className="m-0 font-weight-bold text-primary">
                            {this.props.title}
                            </h6>
                        </div>
                        <div className="card-body">
                            <div>
                                <Chart
                                    chartType="PieChart"
                                    data={this.props.data}
                                    options={this.pieOptions}
                                    graph_id="PieChart"
                                    width={"100%"}
                                    height={"400px"}
                                    legend_toggle
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default PieChart;