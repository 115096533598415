// class BaseApiService {

//  badResponse = (data, status, message) => {
//     return { data, status, message };
//   };

// }

// export default BaseApiService;

export function badResponse(d, s, m) {
  //d: data, s: status, m: message
  return { data: { data: d, status: s, message: m } };
}

export default {
  badResponse
};
