import React, { Component } from "react";
import Table from "Components/Common/table";
//import Active from "Components/Common/active";
import IconLink from "Components/Common/create";
import Moment from "react-moment";

class ChatTable extends Component {
  columns = [
    // { path: "groupName", label: "Chat Name" },
    { path: "repUsername", label: "Agent" },
    { path: "dateCreated", label: "Chat Started" , content: chat => (<Moment fromNow>{chat.dateCreated}</Moment>) },
    { path: "companyName", label: "Website" },
    { path: "clientFullname", label: "Client" },
    { path: "language", label: "Language" },
    // {
    //   key: "active",
    //   path: "Active",
    //   label: "Active",
    //   content: agent => (
    //     <Active
    //       active={agent.Active}
    //       onClick={() => this.props.onActive(agent)}
    //     />
    //   )
    // },
    // {
    //   key: "edit",
    //   path: "",
    //   label: "Edit",
    //   content: agent => (
    //     <IconLink
    //       onClick={() => this.props.onEdit(agent)}
    //       className="fas fa-edit"
    //     />
    //   )
    // },
    // {
    //   key: "Chats",
    //   path: "",
    //   label: "Conversations",
    //   content: agent => (
    //     <React.Fragment>
    //       <IconLink
    //         onClick={() => this.props.onViewChats(agent)}
    //         className="fas fa-tasks"
    //       />
    //     </React.Fragment>
    //   )
    // },

    {
      key: "view",
      path: "",
      label: "View",
      content: chat => (
        <IconLink
          onClick={() => this.props.onViewChat(chat)}
          className="fas fa-desktop"
        />
      )
    },
    {
      key: "remove",
      path: "",
      label: "Remove",
      content: chat => (
        <IconLink
          onClick={() => this.props.onDelete(chat)}
          className="fas fa-trash-alt"
        />
      )
    }
  ];

  render() {
    const { chats, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={chats}
        sortColumn={sortColumn}
        onSort={onSort}
        keyField={"groupId"}
      />
    );
  }
}

export default ChatTable;
