import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom"; //for navigation
import { ToastContainer } from "react-toastify"; //for toast alerts
import {
  ContactSection,
  FeaturesSection,
  Footer,
  Header,
  PackagesSection
} from "./homeLayout";
import Registration from "./registration";
import Login from "./login";
import Logout from "./logout";
import NavBar from "./navbar";
import auth from "Services/authService";
import "react-toastify/dist/ReactToastify.css"; //toast css
import "Template/css/bootstrap.css";
import "./home.css";
import Packages from "./packages";
import Forgot from "./forgot";
import ResetPassword from "./resetPassword";

class HomeApp extends Component {
  state = {};
  componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({ user });
  }

  handleLogout = () => {
    this.setState({ user: null });
  };
  render() {
    const { user } = this.state;
    return (
      <React.Fragment>
        <ToastContainer autoClose={2000} newestOnTop={true} />

        <NavBar user={user} />

        <Switch>
          <Route path="/home/packages" exact component={Packages} />

          <Route
            path="/home/register"
            exact
            render={props => <Registration {...props} />}
          />
          <Route path="/home/login" exact component={Login} />
          <Route path="/home/logout" exact component={Logout} />
          <Route path="/home/access-denied" component={RestrictedAccess} />
          <Route path="/home/not-found" component={NotFound} />
          <Route path="/home/forgot" component={Forgot} />
          <Route path="/home/reset-password" component={ResetPassword} />
          <Route path="/home" component={HomePage} />
          <Route path="/" exact component={HomePage} />
          <Redirect to="/not-found" />
        </Switch>
        <ContactSection />
        <Footer />
      </React.Fragment>
    );
  }
}

export default HomeApp;

const NotFound = () => {
  return (
    <React.Fragment>
      <header className="content-header">
        <div className="container">
          <div className="fixed-center row ">
            <div
              className="col-xl-9 mx-auto text-white wow fadeIn"
              style={{ display: "inline-block" }}
            >
              <div className="mt-5">
                <br />
                <br />
                <h2>Page Not Found</h2>
                <br />
              </div>

              <p className="mt-5" align="center" />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

const RestrictedAccess = () => {
  return (
    <React.Fragment>
      <header className="content-header">
        <div className="container">
          <div className="fixed-center row " style={{}}>
            <div
              className="col-xl-9 mx-auto text-white wow fadeIn"
              style={{ display: "inline-block" }}
            >
              <div className="mt-5">
                <br />
                <br />
                <h2>Access denied</h2>
                <br />
              </div>

              <p className="mt-5" align="center" />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

const HomePage = () => {
  return (
    <React.Fragment>
      <Header />
      <PackagesSection />
      <FeaturesSection />
    </React.Fragment>
  );
};
