import React, { Component } from "react";
import Tabs from "Components/Common/tabs";
import { CustomerProfileSection , CustomerAccountSection } from 'Portal/Customers/profileLayout';
import authService from "Services/authService";
import customerService from "Services/customerService";

class CustomerProfile extends Component {
  state = {
    user: {},
    customer: {}
  };

  async componentDidMount() {
    const user = await authService.getCurrentUser();
    if (user) {
      const { data: customer } = await customerService.get(user.id);
      this.setState({ user: user, customer: customer });
    }
  }

  render() {
    const {user, customer} = this.state;
    return (
      <React.Fragment>
        <div><h3>My Profile</h3></div>
        <Tabs activeTab={this.state.activeTab}>
          <div label="Profile">
            <CustomerProfileSection user={user} customer={customer} />
          </div>
          <div label="Account">
            <CustomerAccountSection user={user} customer={customer} />
          </div>
        </Tabs>
        
      </React.Fragment>
    );
  }
}

export default CustomerProfile;
