import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "Services/authService";

export const ProtectedRoute = ({
  path,
  component: Component,
  render,
  ...rest
}) => {
  return (
    <Route
      path={path}
      render={props => {
        const user = auth.getCurrentUser();
        if (!user)
          return (
            <Redirect
              to={{
                pathname: "/home/login",
                state: { from: props.location }
              }}
            />
          );
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export const AdminRoute = ({ path, component: Component, render, ...rest }) => {
  return (
    <Route
      path={path}
      render={props => {
        const user = auth.getCurrentUser();

        if (!user || user.role !== "Admin")
          return (
            <Redirect
              to={{
                pathname: "/home/access-denied",
                state: { from: props.location }
              }}
            />
          );
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};
