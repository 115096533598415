import React, { Component, Suspense } from "react";
import _ from "lodash";
import { Modal, Row, Col } from "react-bootstrap";
import websiteService from "Services/websiteService";
import Pagination from "Components/Common/pagination";
import { toast } from "react-toastify";
import { paginate } from "Utils/paginate";
import WebsiteTable from "Portal/Admin/Components/website-table";
import { PageHeading } from "../portalLayout";
import customerService from "Services/customerService";
import { parseQueryString } from "Utils/util";
import WebsiteForm from "Portal/Admin/Components/website-form";
import AgentForm from "Portal/Admin/Components/agent-form";
import authService from "Services/authService";
import { CopyToClipboard } from 'react-copy-to-clipboard';

class CustomerWebsites extends Component {
  state = {
    user: {},
    websites: [],
    currentPage: 1,
    pageSize: 10,
    sortColumn: { path: "title", order: "asc" },
    selectedSub: { title: "All" },
    searchKeywords: "",
    subscriptionTypes: [],
    loading: true,
    recordToEdit: null,
    selectedWebsite: 0,
    webModalIsOpen: false,
    agentModalIsOpen: false,
    scriptIsOpen: false,
    clipboardText: ''
  };

  setShow = (modalType, show) => {
    if (modalType === "script") this.setState({ scriptIsOpen: show });
    if (modalType === "website") this.setState({ webModalIsOpen: show });
    if (modalType === "agent") this.setState({ agentModalIsOpen: show });
  };

  openModal = modalType => {
    this.setShow(modalType, true);
  };
  handleNewWebsite = () => {
    this.openModal('website');
  }
  closeModal = modalType => {
    this.setShow(modalType, false);

    this.setState({ recordToEdit: null });

    this.componentDidMount();
  };

  constructor(props) {
    super(props);

    const qParams = parseQueryString(this.props);
    this.state.searchKeywords = qParams.search;
  }

  async componentDidMount() {
    try {
      this.setState({ loading: true });

      const user = authService.getCurrentUser();
      this.setState({ user });

      const data = await this.loadWebsites(user.id);
      const subTypes = await this.loadSubscriptionTypes();

      this.setState({ websites: data, subscriptionTypes: subTypes });
    } catch (ex) {
      toast.error(ex.message);
    } finally {
      this.setState({ loading: false });
    }
  }

  async loadWebsites(customerId) {
    const { data, status, message } = await websiteService.getCustomerWebsites(
      customerId
    );

    if (!status) toast.error(message);
    return data;
  }

  async loadSubscriptionTypes() {
    const {
      data,
      status,
      message
    } = await customerService.getSubscriptionTypes();
    if (!status) toast.error(message);
    const sTypes = [];
    sTypes.push({ title: "All" }, ...data);

    return sTypes;
  }

  handleNewAgent = website => {

    console.log(website);
    this.setState({ selectedWebsite : website });
    this.openModal("agent");

  };

  handleViewAgents = website => {

    this.setState({ recordToEdit: website });

    this.props.history.push(
      `/portal/customers/agents?customerId=${website.customerId}&websiteId=${website.companyId}`
    );
  };

  handleViewChats = website => {

    this.setState({ recordToEdit: website });
    this.props.history.push(
      `/portal/customers/chats?customerId=${website.customerId}&websiteId=${website.companyId}`
    );
    //this.openModal("agent");
  };

  handleEdit = website => {

    this.setState({ recordToEdit: website });
    this.openModal("website");

  };
  async updateWebsite(website) {
    //const originalData = this.state.websites;
    const { status } = await websiteService.update(website);

    if (status) {
      return true;
    } else {
      // this.setState({ websites: originalData });
      return false;
    }
  }

  handleDelete = async website => {
    if (window.confirm("Are you sure you wish to delete this website?")) {
      const originalData = this.state.websites;

      const websites = this.state.websites.filter(
        c => c.companyId !== website.companyId
      );
      this.setState({ websites });

      const { message, status } = await websiteService.remove(
        website.companyId
      );
      if (status) toast.success(message);
      else {
        toast.error(message);
        this.setState({ websites: originalData });
      }
    }
  };

  handleActivity = async website => {
    //toast("Active link clicked");
    const originalWebsites = [...this.state.websites];
    const websites = [...this.state.websites];
    const index = websites.indexOf(website);
    websites[index] = { ...websites[index] };
    websites[index].isActive = !websites[index].isActive;

    await this.updateWebsite(websites[index]).then(result => {

      if (result) {
        toast.success("Selected website's record updated");
        this.setState({ websites });
      } else {
        toast.error("Failed to update website");
        this.setState({ websites: originalWebsites });
      }
    });
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSubscriptionSelect = sub => {
    this.setState({ selectedSub: sub, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleSearch = event => {
    this.setState({ searchKeywords: event.target.value });
  };

  handleOpenDemo = website => {
    var url = process.env.REACT_APP_DEMO_URL+website.accessSecret;
    window.open(url,"_blank");
  };
  handleOpenScript = website => {
    this.setState( { recordToEdit: website } );
    this.openModal("script");
  };
  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedSub,
      websites: allWebsites,
      searchKeywords
    } = this.state;

    let filtered =
      selectedSub && selectedSub.subscriptionId
        ? allWebsites.filter(
            m =>
              m.subscriptionId &&
              m.subscriptionId === selectedSub.subscriptionId.toString()
          )
        : allWebsites;

    filtered = searchKeywords
      ? filtered.filter(
          m =>
            (m.customerEmail &&
              m.customerEmail
                .toLowerCase()
                .indexOf(searchKeywords.toLowerCase()) !== -1) ||
            (m.title &&
              m.title.toLowerCase().indexOf(searchKeywords.toLowerCase()) !==
                -1) ||
            (m.url &&
              m.url.toLowerCase().indexOf(searchKeywords.toLowerCase()) !== -1)
        )
      : filtered;

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const websites = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: websites };
  };
  handleCopyScript = () => {
    toast.success("Code successfully copied to clipboard.");
  };

  render() {
    // if (this.state.loading) return <Loader />;

    const { length: count } = this.state.websites;
    const {
      pageSize,
      currentPage,
      sortColumn
      
    } = this.state;

    if (count === 0) return <p>There are no websites in the system</p>;

    const { totalCount, data: websites } = this.getPagedData();
    const codeSpanStyle = {
      backgroundColor: 'white', 
      border: '#ccc 2 solid',
      margin: '10px', 
      padding: '10px', 
      width: '80%',
      wordWrap: 'normal', 
      fontSize: '18px', 
      fontFamily: 'sans-serif'
    };
    var accessSecret = this.state.recordToEdit === null ? "" : this.state.recordToEdit.accessSecret;
    const innerCode = "&lt;!—LINGMO LIVE CHAT CODE STARTS HERE --&gt; \n"
    + '&lt;button onclick="OnChatUs()" class="btn btn-default" style="position: fixed; right: 10px; bottom: 10px;"&gt;Chat us&lt;/button&gt; \n'
    + "&lt;script&gt; \n"
    + "function OnChatUs() { \n"
    + `var url = "&lt;span/&gt;' + ${process.env.REACT_APP_DEMO_URL}${accessSecret} + '&lt;/span&gt;"; \n`
    + 'var props = "directories=no" + \n'
    + '",titlebar=no" + \n'
    + '",toolbar=no" + \n'
    + '",location=no" + \n'
    + '",status=no" + \n'
    + '",menubar=no" + \n'
    + '",scrollbars=no" + \n'
    + '",resizable=no" + \n'
    + '",width=350" + \n'
    + '",height=400"; \n'
    + 'window.open(url, "mywindow", props); \n'
    + "} \n"
    + "&lt;/script&gt; \n"
    + "&lt;!—LINGMO LIVE CHAT CODE ENDS HERE --&gt; \n"
    ;
    var decode = require('decode-html');  
    return (
      <React.Fragment>
        <PageHeading heading="Websites" />
        <div className="row">
          <div className="col-11">
            <div className="flex-row">
              <div className="flex-col">
                <button variant="primary" onClick={this.handleNewWebsite}>
                  Add new website
                </button>
              </div>
              <div className="flex-col-right">
                <input
                  value={this.state.searchKeywords}
                  name="txtSearch"
                  type="text"
                  onChange={this.handleSearch}
                ></input>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p>Showing {totalCount} website accounts.</p>
                <Suspense fallback={<div>loading...</div>}>
                  <WebsiteTable
                    websites={websites}
                    sortColumn={sortColumn}
                    onActive={this.handleActivity}
                    onDelete={this.handleDelete}
                    onSort={this.handleSort}
                    onEdit={this.handleEdit}
                    onNewAgent={this.handleNewAgent}
                    onViewAgents={this.handleViewAgents}
                    onViewChats={this.handleViewChats}
                    onOpenDemo={this.handleOpenDemo}
                    onOpenScript={this.handleOpenScript}
                  />
                </Suspense>
                <Pagination
                  itemsCount={totalCount}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  onPageChange={this.handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>

        <Modal
          size="lg"
          show={this.state.webModalIsOpen}
          onHide={() => this.closeModal("website")}
          dialogClassName="modal-dialog"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add new website</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <WebsiteForm
              customerId={this.state.user.id}
              recordToEdit={this.state.recordToEdit}
              onClose={() => this.closeModal("website")}
            />
          </Modal.Body>
        </Modal>

        <Modal size="lg"
          show={this.state.scriptIsOpen}
          onHide={() => this.closeModal("script")}
        >
          <Modal.Header closeButton>
            <Modal.Title>Script to Embed</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>

            Please embed following script at the end of your web page's Html, just before closing &lt;/body&gt; tag:

            <span style={codeSpanStyle}>
              <pre id="embedCode">
                <code dangerouslySetInnerHTML={{ __html: innerCode}}>
                </code>
              </pre>
            </span>
    
        <Row>
            <Col md={{ span: 4, offset: 4}}>
              <CopyToClipboard onCopy={this.handleCopyScript} text={decode(innerCode)}>
                <button className="btn btn-primary">Copy to clipboard</button>
              </CopyToClipboard>
            </Col>
        </Row>
        </div>            
          </Modal.Body>
        </Modal>
        <Modal
          size="lg"
          show={this.state.agentModalIsOpen}
          onHide={() => this.closeModal("agent")}
          dialogClassName="modal-dialog"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add new Agent</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AgentForm
              companyId={this.state.selectedWebsite.companyId}
              recordToEdit={null}
              onClose={() => this.closeModal("agent")}
            />
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default CustomerWebsites;
