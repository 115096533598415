import React from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import Form from "Components/Common/form";
import websiteService from "Services/websiteService";

class WebsiteForm extends Form {
  state = {
    data: {
      customerId: 0,
      companyId: 0,
      title: "",
      url: "",
      isActive: false,
      status: 0
    },
    errors: {},
    isAdmin: false,
    recordToEdit: null
  };

  validationSchema = {
    customerId: Joi.number(),
    companyId: Joi.number(),
    title: Joi.string()
      .required()
      .max(100)
      .min(6)
      .label("Website title"),
    url: Joi.string()
      .required()
      .min(6)
      .max(100)
      .label("Website url"),
    isActive: Joi.boolean()
      .label("Active")
      .optional()
      .allow(null),
    status: Joi.number()
      .label("Status")
      .optional()
      .allow(null)
      .allow(0)
  };

  async componentDidMount() {

    if (this.props.recordToEdit) {

      this.setState({
        data: this.props.recordToEdit,
        recordToEdit: this.props.recordToEdit
      });
    } else {
      const { data } = this.state;
      data.customerId = this.props.customerId;

      this.setState({ data });
    }
  }

  doSubmit = async () => {

    let status = "";
    let message = "";

    const { data: website } = this.state;

    if (website.companyId > 0) {
      const resS = await websiteService.update(website);
      status = resS.status;
      message = resS.message;
    } else {
      const resF = await websiteService.create(website);
      status = resF.status;
      message = resF.message;
    }

    if (status) {
      toast.success(message);
      const { onClose } = this.props;
      onClose();
    } else {
      toast.error(message);

      const errors = { ...this.state.errors };
      errors.email = message;
      this.setState({ errors });
    }
  };

  doCancel = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    return (
      <React.Fragment>
        <div className="">
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                {this.renderInput("title", "Website title")}
              </div>
              <div className="col-md-6">
                {this.renderInput("url", "Website Url")}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                {this.renderCheckbox("isActive", "Active", "big-checkbox")}
              </div>
              <div className="col-md-6"></div>
            </div>

            <div className="row">
              <div className="col-md-8">
                <div className="form-group">
                  {this.renderButton(
                    "Add",
                    "btn btn-primary",
                    this.handleSubmit,
                    "submit",
                    true
                  )}
                  <span style={{ minWidth: "30px" }}>&nbsp;</span>
                  {this.renderButton("Cancel", "btn", this.doCancel)}
                </div>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default WebsiteForm;
