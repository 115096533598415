import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';

class ScheduleWebsiteForm extends Component {
    constructor(props) {
        super(props);

        const data = props.record;
        this.state = {
            isFullDay: data.isFullDay,
            daysOfWeek: data.daysOfWeek,
            fromTime: data.todfrom,
            toTime: data.todto,
            utcoffset: data.timeZone,
            offlineEmail: data.offlineEmail,
            offlineMessage: data.offlineMessage
         }
    }
    handleFullDayActiveChanged = () => {
        this.setState({isFullDay: !this.state.isFullDay});
    }
    handleDayChange = (evt) => {
        var bit = +evt.target.value;
        const newDaysOfWeek = (this.state.daysOfWeek ^ (1 << bit));
        this.setState({daysOfWeek: newDaysOfWeek});
    }
    handleFromTimeChange = (evt) => {
        var time = evt.target.value;
        this.setState({fromTime: time});
    }
    handleToTimeChange = (evt) => {
        var time = evt.target.value;
        this.setState({toTime: time});
    }
    handleUtcChange = (evt) => {
        var offset = evt.target.value;
        this.setState({utcoffset: offset});
    }
    handleMessageChange = evt => {
        this.setState({offlineMessage: evt.target.value});
    }
    handleEmailChange = evt => {
        this.setState({offlineEmail: evt.target.value});
    }

    handleSubmit = evt => {
        evt.preventDefault();
        const data = {
            isFullDay: this.state.isFullDay,
            offlineEmail: this.state.offlineEmail,
            offlineMessage: this.state.offlineMessage,
            timezone: this.state.utcoffset,
            todfrom: this.state.fromTime,
            todto: this.state.toTime,
            daysOfWeek: this.state.daysOfWeek 
        };
        this.props.onSubmit(data);
    }
    render() { 
        return ( 
            <React.Fragment>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="switchMode">
                        <Form.Check 
                            type="switch"
                            checked={this.state.isFullDay}
                            label="Full day active"
                            onChange={this.handleFullDayActiveChanged}
                        />
                    </Form.Group>
                    <div hidden={this.state.isFullDay}>
                        <Form.Group controlId="cbSun">
                            <Form.Check 
                                type="checkbox"
                                checked={this.state.daysOfWeek & 0x1}
                                label="Sunday"
                                value="0"
                                onChange={this.handleDayChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="cbMon">
                            <Form.Check 
                                type="checkbox"
                                checked={this.state.daysOfWeek & 0x2}
                                label="Monday"
                                onChange={this.handleDayChange}
                                value="1"
                            />
                        </Form.Group>
                        <Form.Group controlId="cbTue">
                            <Form.Check 
                                type="checkbox"
                                checked={this.state.daysOfWeek & 0x4}
                                label="Tuesday"
                                onChange={this.handleDayChange}
                                value="2"
                            />
                        </Form.Group>
                        <Form.Group controlId="cbWed">
                            <Form.Check 
                                type="checkbox"
                                checked={this.state.daysOfWeek & 0x8}
                                label="Wednesday"
                                onChange={this.handleDayChange}
                                value="3"
                            />
                        </Form.Group>
                        <Form.Group controlId="cbThu">
                            <Form.Check 
                                type="checkbox"
                                checked={this.state.daysOfWeek & 0x10}
                                label="Thursday"
                                onChange={this.handleDayChange}
                                value="4"
                            />
                        </Form.Group>
                        <Form.Group controlId="cbFri">
                            <Form.Check 
                                type="checkbox"
                                checked={this.state.daysOfWeek & 0x20}
                                label="Friday"
                                onChange={this.handleDayChange}
                                value="5"
                            />
                        </Form.Group>
                        <Form.Group controlId="cbSat">
                            <Form.Check 
                                type="checkbox"
                                checked={this.state.daysOfWeek & 0x40}
                                label="Saturday"
                                onChange={this.handleDayChange}
                                value="6"
                            />
                        </Form.Group>
                        <Form.Group controlId="TimingFrom">
                            <TimeDisplay 
                            label="From Time" value={this.state.fromTime} onChange={this.handleFromTimeChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="TimingTo">
                            <TimeDisplay 
                            label="To Time" value={this.state.toTime} onChange={this.handleToTimeChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="TimeZone">
                            <UTCDisplay 
                            label="UTC Time zone" value={this.state.utcoffset} onChange={this.handleUtcChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="offlineEmail">
                            <Form.Label>Offline Email</Form.Label>
                            <Form.Control 
                            type="email"
                            placeholder="offline email address"
                            value={this.state.offlineEmail}
                            onChange={this.handleEmailChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="offlineMessage">
                            <Form.Label>Offline Message</Form.Label>
                            <Form.Control 
                            type="text"
                            placeholder="offline message"
                            value={this.state.offlineMessage}
                            onChange={this.handleMessageChange}
                            />
                        </Form.Group>
                    </div>
                    <Button variant="primary" type="submit">
                        Save Changes
                    </Button>
                </Form>
            </React.Fragment>
         );
    }
}
function TimeDisplay(props){
    var disp=[];
    for(var h = 0 ; h < 24 ; h ++) for (var m = 0; m < 60 ; m += 5) 
    { disp.push((h < 10 ? "0"+h:h)+":"+(m<10 ? "0"+m:m)); }
    return <React.Fragment>
        <Form.Label>{props.label}</Form.Label>
        <Form.Control as="select" onChange={props.onChange} value={props.value}>
            {disp.map((e,i)=>(
                <option key={i} value={e}>{e}</option>
            ))}
        </Form.Control>
    </React.Fragment>;
}

function UTCDisplay(props){
    var disp=[];
    var vals=[];
    for(var h = -14 ; h < 14 ; h ++) 
        for (var m = 0; m < 60 ; m += 15) { 
            disp.push((h < 0 ? "-":"+")+(Math.abs(h) < 10 ? "0"+Math.abs(h):Math.abs(h))+":"+(m<10 ? "0"+m:m)); 
            vals.push(h + m/60);
        }
    return <React.Fragment>
        <Form.Label>{props.label}</Form.Label>
        <Form.Control as="select" onChange={props.onChange} value={props.value}>
            {disp.map((e,i)=>(
                <option key={i} value={vals[i]}>{e}</option>
            ))}
        </Form.Control>
    </React.Fragment>;
}
export default ScheduleWebsiteForm;