import React, { Component } from "react";
import { PageHeading } from "Portal/portalLayout";

class AdminReports extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <PageHeading heading="Admin Reports" />
      </React.Fragment>
    );
  }
}

export default AdminReports;
