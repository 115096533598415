// import http from "./httpService.js";
// import authService from "./authService";
// //import aService from "./aService";
// import Logger from "./logService";
// import * as config from "../config.json";

async function init() {
  // const token = await authService.token(config.jwtCredentials);
  // const headers = {
  //   Authorization: "Bearer " + token,
  //   Accept: "application/json, text/plain, */*",
  //   "Content-Type": "application/json"
  // };
  // http.defaults.headers.common = headers;
  // Logger.info("Axios default request headers are set");
}

export default {
  init
};
