import React, { Component } from "react";
import { PageHeading } from "Portal/portalLayout";

class CustomerNotifications extends Component {
  state = {};
  render() {
    return <PageHeading heading="Notifications" />;
  }
}

export default CustomerNotifications;
