import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";
import logger from "./Services/logService";
import apiStartup from "./Services/httpStartup";

import App from "app";
import "index.css";

logger.init(); //for Raven logging
apiStartup.init(); //initialize apiStartup

ReactDOM.render(
  //const {user} = this.state;
  <React.Fragment>
    <App />
  </React.Fragment>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
