import React, { Component } from 'react';
import Service from 'Services/paymentService';
import { toast } from "react-toastify";
 
class PayPalInfo extends Component {
    state = {
        win : null,
        timer: 0,
        paypalLoaded: false,
        closedIt: false
    };
    ProceedToPaypal = async () => {
        var response = await Service.requestPaypal(this.props.subscription);
        if(response.IsError)
            toast.error(response.description);
        else
            toast.success(response.description);

        var w = window.open(response.link,"_blank");
        var t = setInterval( () => {
            try {
                if(this.state.win.load){
                    clearInterval(this.state.timer);
                }

                if(this.state.paypalLoaded)
                    this.setState({ closedIt: true});
            } catch (error) {
                this.setState( {paypalLoaded: true} );
            }
            
            if(this.state.closedIt === true) {
                clearInterval(this.state.timer);
                this.props.onSuccess();
            }
        },1000);

        this.setState({ win: w, timer: t});
    }
    render() { 
        return ( 
        <React.Fragment>
                <hr className="mb-4" />
                <button 
                className="btn btn-primary btn-lg btn-block" 
                type="submit"
                onClick={this.ProceedToPaypal}
                >Continue to Paypal</button>
        </React.Fragment> 
        );
    }
}
 
export default PayPalInfo;