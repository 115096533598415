import React from "react";
import UpgradeForm from 'Portal/Customers/Subscriptions/upgrade-form'

const Upgrade = (props) => {
  return (
    <React.Fragment>
      <h3>Upgrade your current subscription</h3>
      <br />
      <UpgradeForm activeSubscription={props.activeSubscription} onSuccess={props.onSuccess}></UpgradeForm>
    </React.Fragment>
  );
};

export default Upgrade;
