import React from "react";

const Select = ({
  name,
  label,
  textProperty,
  valueProperty,
  options,
  defaultValue,
  error,
  ...rest
}) => {
  if ( options === undefined) options = [];
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <select
        name={name}
        id={name}
        value={defaultValue}
        className="form-control"
        {...rest}
      >
        <option value="" />
        {options.map(option => (
          <option key={option[valueProperty]} value={option[valueProperty]}>
            {option[textProperty]}
          </option>
        ))}
      </select>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Select;
