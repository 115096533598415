import React , {Component} from 'react';
import Select from 'Components/Common/select';
import custService from "Services/customerService";
import { toast } from 'react-toastify';
import PropTypes from 'prop-types'
import _ from 'lodash'


class SelectSubscriptionTypes extends Component {
    state = { subscription_types: [] };

    async loadSubscriptionTypes() {
        const { data, status, message } = await custService.getSubscriptionTypes();
        if (!status) toast.error(message);
        return data;
    }

    async componentDidMount() {
        var data = await this.loadSubscriptionTypes();
        this.setState({ subscription_types: data});
    }

    onValueChange = (e) => {
        const id = e.currentTarget.value;
        const obj = _.find(this.state.subscription_types,(o)=> o.subscriptionId === +id)
        
        e.data = obj;
        if(this.props.onChange !== undefined)
             this.props.onChange(e);
    }
    render() { 
        return ( <React.Fragment>
            <Select name={this.props.name}
                    label={this.props.label}
                    textProperty={this.props.textProperty}
                    valueProperty={this.props.valueProperty}
                    options={this.state.subscription_types}
                    onChange={this.onValueChange}
                    />
        </React.Fragment> );
    }
}

SelectSubscriptionTypes.propTypes = 
{
    textProperty: PropTypes.string,
    valueProperty: PropTypes.string
}

SelectSubscriptionTypes.defaultProps = 
{
    textProperty: "title",
    valueProperty: "subscriptionId"
}

export default SelectSubscriptionTypes;