import React from "react";
import { Link } from "react-router-dom";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { useState } from "react";
export const AboutPage = () => {
  return (
    <header className="">
      <div className="container">
        <div className="row">
          <div className="col-xl-9 mx-auto text-white wow fadeIn">
            <div className="mt-5" align="center">
              <h2>About Us</h2>
            </div>
            <p className="mt-5" align="center">
              Some text for About us page.
            </p>
          </div>
        </div>
      </div>
    </header>
  );
};

export const PurchasePage = () => {
  return (
    <header className="">
      <div className="container">
        <div className="row">
          <div className="col-xl-9 mx-auto text-white wow fadeIn">
            <div className="mt-5" align="center">
              <h2>Purchase</h2>
            </div>
            <p className="mt-5" align="center">
              Some text for purchase page.
            </p>
          </div>
        </div>
      </div>
    </header>
  );
};

export const Header = () => {
  const [showFreeTrial, setShowFreeTrial] = useState(false);
  return (
    <header className="masthead text-white text-center">
      <Modal show={showFreeTrial} centered onHide={() => setShowFreeTrial(false)}>
        <Modal.Body>
          <Row className="justify-content-md-center mb-5">
            <Col md="auto"><img src="/template/img/lingmo_live_logo.png" alt="lingmo logo" /></Col>
          </Row>
          <Row className="justify-content-md-center mb-5">
            <Col md="auto"><h3>Welcome to Lingmo Live Chat</h3></Col>
          </Row>
          <Row  className="justify-content-md-center mb-5">
            <Col className="text-center">
              <h4>Live Chat Helpdesk built to chat to internatioanl website visitors</h4>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              Start a customer chat, search for a language, 
              write a message and respond to the translated 
              chat as a customer representative
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-md-center">
          <Button className="btn btn-default mr-2 mb-2" onClick={() => setShowFreeTrial(false)}>Close</Button>
          <Link
              to="/home/register"
              className="btn btn-primary mr-2 mb-2 page-scroll"
            >
              Start Free Trial
            </Link>
        </Modal.Footer>
      </Modal>
      <div className="container">
        <div className="row">
          <div className="col-xl-9 mx-auto text-white wow fadeIn">
            <div className="mt-5" align="center">
              <h2 className="pt-4 myshadow" align="center" data_temp_dwid="1">
                LINGMO LIVE CHAT
                <br /> <strong>We’ve made Live Chat Translate! </strong>{" "}
              </h2>
              <h5 align="center">
                Try our FREE demo today and unleash the potential to reach new
                international markets today!{" "}
              </h5>
            </div>
            <p className="mt-5" align="center">
              {/* <Link
                to="/home/register"
                className="btn btn-primary mr-2 mb-2 page-scroll"
              >
                Free Trial
              </Link> */}
              <button className="btn btn-primary mr-2 mb-2 page-scroll" href="#" onClick={() => setShowFreeTrial(true)} >Free Trial</button>
              <Link
                to="/home/packages"
                className="btn btn-white mb-2 page-scroll"
              >
                Packages
              </Link>
            </p>
          </div>
        </div>
      </div>
    </header>
  );
};
export const PackagesSection = () => {
  return (
    <section className="bg-white p-0" id="listme">
      <div
        className="container-fluid"
        style={{ background: "#1d76bc", paddingTop: "150px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3 col-sm-8 offset-sm-2 col-xs-12 text-center">
              <div className="lead pt-3">
                {" "}
                <h4 style={{ color: "#FFFFFF" }}>
                  Our Packages <br /> Please select from the following Lingmo
                  Live Chat packages
                </h4>{" "}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-3 col-lg-5 col-md-6 col-sm-12 fadeIn text-center">
              <div className="card" style={{ height: "32rem" }}>
                <div className="card-body">
                  <h5 className="card-title pt-4 text-orange">Demo</h5>
                  <h5 className="card-title text-primary pt-4">
                    7 Days Free Trial
                  </h5>
                  <br />
                  <p className="card-text text-muted pb-3 border-bottom" />
                  <ul className="list-unstyled pricing-list">
                    <li>Trial Platinum features for 7 days. No obligation</li>

                    <li>No credit card required</li>
                    <li>Easy Account Setup</li>
                  </ul>

                  <Link
                    to="/home/register"
                    className="btn btn-primary btn-radius"
                  >
                    Start Now
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-5 col-md-6 col-sm-12 fadeIn text-center">
              <div className="card" style={{ height: "32rem" }}>
                <div className="card-body">
                  <h5 className="card-title pt-4 text-orange">Basic</h5>
                  <h5 className="card-title text-primary pt-4">
                    Great value, no frills
                  </h5>
                  <br />
                  <p className="card-text text-muted pb-3 border-bottom" />
                  <ul className="list-unstyled pricing-list">
                    <li>Unlimited agents</li>
                    <li>180 day chat history</li>
                    <li>No license Fee</li>
                    <br />
                  </ul>
                  <Link
                    to="/home/register"
                    className="btn btn-primary btn-radius"
                  >
                    Subscribe Now
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-5 col-md-6 col-sm-12 fadeIn text-center">
              <div className="card" style={{ height: "32rem" }}>
                <div className="card-body">
                  <h5 className="card-title pt-4 text-orange">
                    Platinum{" "}
                    <small className="badge bg-success small-xs">Popular</small>
                  </h5>
                  <h5 className="card-title text-primary pt-4">
                    Tailored to meet your company needs
                  </h5>
                  <p className="card-text text-muted pb-3 border-bottom" />
                  <ul className="list-unstyled pricing-list">
                    <li>Trained to recognise Industry Specific Terminology</li>
                    <li>Reporting and Analytics</li>
                    <li>Multiplatform</li>
                  </ul>

                  <Link
                    to="/home/register"
                    className="btn btn-primary btn-radius"
                  >
                    Subscribe Now
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-5 col-md-6 col-sm-12 fadeIn text-center">
              <div className="card" style={{ height: "32rem" }}>
                <div className="card-body">
                  <h5 className="card-title pt-4 text-orange">Enterprise</h5>
                  <h5 className="card-title text-primary pt-4">
                    The complete Suite
                  </h5>
                  <br />

                  <p className="card-text text-muted pb-3 border-bottom" />
                  <ul className="list-unstyled pricing-list">
                    <li>Unlimited Chats & 300 agents</li>
                    <li>Fully customised & trained</li>
                    <li>One Annual Payment</li>
                    <br />
                  </ul>
                  <a
                    href="mailto:hello@lingmo.global"
                    className="btn btn-primary btn-radius"
                  >
                    Discuss
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const FeaturesSection = () => {
  return (
    <section className="bg-light" i="pricing">
      <div className="container-fluid">
        <div className="row d-md-flex mt-5">
          <div className="col-sm-6 p-0 wow fadeInLeft">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                title="Video"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/hoyLG7PNkWQ"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen="allowfullscreen"
              />
            </div>
          </div>
          <div className="col-sm-6 pl-5 pr-5 pt-5 pb-4 wow fadeInRight">
            <h3>
              <span>
                Chat in 80 Languages and make your company Multilingual today!{" "}
              </span>
            </h3>
            <p className="lead pt-4">
              All the translating between chats happens behind the scenes so the
              agent and customer can focus on their chat!{" "}
            </p>
            <ul className="pt-4 pb-3 list-default">
              <li>Improves customer satisfaction </li>
              <li>
                Reduces wait time for answering foreign speaking customers{" "}
              </li>
              <li>Make every customer service agent multilingual </li>
              <li>
                Implement our API or plugin into your preexisting Live Chat or
                Chatbot on your website{" "}
              </li>
              <li>
                Customers select their language from the drop down on your live
                chat tab and send a chat in their native language.{" "}
              </li>
              <li>
                The agent selects their native language, then read and reply to
                the chat message.{" "}
              </li>

              <li>
                The customer receives the message in their pre-selected
                language.{" "}
              </li>
            </ul>
            <Link
              to="/home/register"
              className="btn btn-primary mr-2 page-scroll"
            >
              Start your FREE trial today
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export const ContactSection = () => {
  return (
    <section className="bg-texture-collage p-0" id="contact">
      <div className="container">
        <div className="row d-md-flex text-white text-center wow fadeIn">
          <div className="col-sm-4 p-5 col-lg-12 col-md-8">
            <p>
              <a
                href="mailto:talk2me@lingmo.global"
                target="_blank"
                rel="noopener noreferrer"
              >
                <font color="#FFFFFF">
                  <em className="ion-ios-email-outline icon-md" />
                </font>
              </a>
            </p>
            <p className="lead">
              <a
                href="mailto:talk2me@lingmo.global"
                target="_blank"
                rel="noopener noreferrer"
              >
                <font color="#ffffff">Contact us</font>
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export const Footer = () => {
  return (
    <section className="bg-footer" id="connect">
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3 col-sm-8 offset-sm-2 col-xs-12 text-center wow fadeIn">
            <h1>
              <img
                src="./template/img/lingmo_live_bot.png"
                width="120"
                height="64"
                alt=""
              />
            </h1>
            <p className="mt-4">
              <a
                href="https://twitter.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <em className="ion-social-twitter text-twitter-alt icon-sm mr-3 icon-blue" />
              </a>
              <a
                href="https://facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <em className="ion-social-facebook text-facebook-alt icon-sm mr-3 icon-blue" />
              </a>

              <a
                href="https://www.linkedin.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <em className="ion-social-linkedin text-linkedin-alt icon-sm mr-3 icon-blue" />
              </a>
            </p>
            <p className="pt-2 text-muted">
              &copy; 2019-2020{" | "}
              <a
                href="https://lingmointernational.com"
                target="new"
                rel="noopener noreferrer"
                className="green-link"
              >
                Lingmo International
              </a>
              {" | "}
              ABN 51 608 497 196
            </p>
            <p>
              <a className="green-link" href="/" onClick={(e) => e.preventDefault() }>Terms and Conditions</a>
              {" | "}
              <a className="green-link" href="/" onClick={(e) => e.preventDefault() }>Privacy Policy</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
