import React from "react";
import { Link } from "react-router-dom";

export const SideBar = ({ user, customer }) => {
  var demo_link = process.env.REACT_APP_DEMO_URL; //"https://t2u-rep.lingmo-api.com/demo?secret=";
  if (customer === undefined) return (<span></span>);
  var websites = customer.companies;
  return (
    <React.Fragment>
      {/*<!-- Sidebar -->*/}
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar"
      >
        {/*<!-- Sidebar - Brand -->*/}
        <li className="nav-item active">
          <Link
            to="/"
            className="sidebar-brand d-flex align-items-center justify-content-center"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <div className="sidebar-brand-text mx-3">
              <img
                src="/template/img/lingmo_live_logo.png"
                width="120"
                height="64"
                alt=""
              />
            </div>
          </Link>
        </li>

        {/*<!-- Divider -->*/}
        <hr className="sidebar-divider my-0" />

        {/*<!-- Nav Item - Dashboard -->*/}
        <li className="nav-item active">
          <Link to="/portal/dashboard" className="nav-link">
            <i className="fas fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </Link>
        </li>

        {user.role === "Admin" && (
          <React.Fragment>
            {/*<!-- Divider -->*/}
            <hr className="sidebar-divider" />
            <div className="sidebar-heading">Administration</div>

            <li className="nav-item">
              <div
                href="#"
                className="nav-link collapsed"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                <i className="fas fa-fw fa-cog"></i>
                <span>User Management</span>
              </div>
              <div
                id="collapseTwo"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Admin options:</h6>
                  <Link to="/portal/admin/customers" className="collapse-item">
                    Customers
                  </Link>
                  <Link to="/portal/admin/websites" className="collapse-item">
                    Websites
                  </Link>
                  <Link to="/portal/admin/agents" className="collapse-item">
                    Agents
                  </Link>
                  {/* <Link
                    to="/portal/admin/reports"
                    className="collapse-item"
                    href="cards.html"
                  >
                    Reports
                  </Link> */}
                </div>
              </div>
            </li>

            {/* <li className="nav-item">
              <div
                className="nav-link collapsed"
                data-toggle="collapse"
                data-target="#collapseUtilities"
                aria-expanded="true"
                aria-controls="collapseUtilities"
              >
                <i className="fas fa-fw fa-wrench"></i>
                <span>Payments</span>
              </div>
              <div
                id="collapseUtilities"
                className="collapse"
                aria-labelledby="headingUtilities"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Options:</h6>
                  <Link
                    to="/portal/admin/payments?type=m"
                    className="collapse-item"
                  >
                    Monthly payments
                  </Link>
                  <Link
                    to="/portal/admin/payments?type=a"
                    className="collapse-item"
                  >
                    All payments
                  </Link>
                  <Link
                    to="/portal/admin/payments?type=p"
                    className="collapse-item"
                  >
                    Pending
                  </Link>
                  <Link
                    to="/portal/admin/payments?type=oh"
                    className="collapse-item"
                  >
                    On hold
                  </Link>
                  <Link
                    to="/portal/admin/payments?type=r"
                    className="collapse-item"
                  >
                    Refunded
                  </Link>
                </div>
              </div>
            </li> */}
          </React.Fragment>
        )}

        {/*<!-- Divider -->*/}
        <hr className="sidebar-divider" />

{user.role === "Customer" && (
      websites.map(item => (
        <li className="nav-item" key={item.companyId}>
          <a href={demo_link+item.accessSecret} target="_blank" rel="noopener noreferrer" className="nav-link">
            <i className="fas fa-fw fa-signout"></i>
            <span>Demo ({item.title})</span>
          </a>
        </li>
      )))}
        {/*<!-- Heading -->*/}
        {/* <div className="sidebar-heading">My portal</div> */}

        {/*<!-- Nav Item - Pages Collapse Menu -->*/}
        <li className="nav-item">
          <div
            className="nav-link collapsed"
            href="#"
            data-toggle="collapse"
            data-target="#websites"
            aria-expanded="true"
            aria-controls="collapsePages"
          >
            <i className="fas fa-fw fa-folder"></i>
            <span>My websites</span>
          </div>
          <div
            id="websites"
            className="collapse"
            aria-labelledby="headingPages"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <Link to="/portal/customers/websites" className="collapse-item">
                All
              </Link>
              {customer.companies &&
                customer.companies.map(company => {
                  return (
                    <Link
                      key={company.companyId}
                      to={`/portal/customers/websites?id=${company.companyId}`}
                      className="collapse-item"
                    >
                      {company.title}
                    </Link>
                  );
                })}
            </div>
          </div>
        </li>

        {/*<!-- Nav Item - Pages Collapse Menu -->*/}
        <li className="nav-item">
          <div
            className="nav-link collapsed"
            href="#"
            data-toggle="collapse"
            data-target="#agents"
            aria-expanded="true"
            aria-controls="collapsePages"
          >
            <i className="fas fa-fw fa-folder"></i>
            <span>Agents</span>
          </div>
          <div
            id="agents"
            className="collapse"
            aria-labelledby="headingPages"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <Link
                to={`/portal/customers/agents?customerId=${customer.customerId}`}
                className="collapse-item"
              >
                All Agents
              </Link>
              {customer.agents &&
                customer.agents.map(agent => {
                  return (
                    <Link
                      key={agent.userId}
                      to={`/portal/customers/agents?customerId=${customer.customerId}&websiteId=${agent.companyId}&agentId=${agent.userId}`}
                      className="collapse-item"
                    >
                      {agent.clientUsername}
                    </Link>
                  );
                })}
            </div>
          </div>
        </li>

        {/*<!-- Nav Item - Pages Collapse Menu -->*/}
        <li className="nav-item">
          <div
            className="nav-link collapsed"
            href="#"
            data-toggle="collapse"
            data-target="#conversations"
            aria-expanded="true"
            aria-controls="collapsePages"
          >
            <i className="fas fa-fw fa-table"></i>
            <span>Chats</span>
          </div>
          <div
            id="conversations"
            className="collapse"
            aria-labelledby="headingPages"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <Link
                to={`/portal/customers/chats?customerId=${customer.customerId}`}
                className="collapse-item"
              >
                All Chats
              </Link>
            </div>
          </div>
        </li>

        {/*<!-- Nav Item - Pages Collapse Menu -->*/}
        <li className="nav-item">
          <div
            className="nav-link collapsed"
            href="#"
            data-toggle="collapse"
            data-target="#reports"
            aria-expanded="true"
            aria-controls="collapsePages"
          >
            <i className="fas fa-fw fa-chart-area"></i>
            <span>Charts &amp; Reports</span>
          </div>
          <div
            id="reports"
            className="collapse"
            aria-labelledby="headingPages"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <Link
                to="/portal/customers/reports?type=website"
                className="collapse-item"
              >
                Website summary
              </Link>
              <Link
                to="/portal/customers/reports?type=agents"
                className="collapse-item"
              >
                Agents report
              </Link>
            </div>
          </div>
        </li>

        {/*<!-- Nav Item - Pages Collapse Menu -->*/}
        {/* <li className="nav-item">
          <div
            className="nav-link collapsed"
            href="#"
            data-toggle="collapse"
            data-target="#account"
            aria-expanded="true"
            aria-controls="collapsePages"
          >
            <i className="fas fa-fw fa-user"></i>
            <span>Account Settings</span>
          </div>
          <div
            id="account"
            className="collapse"
            aria-labelledby="headingPages"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <Link to="/portal/customers/profile" className="collapse-item">
                My profile
              </Link>
              <Link
                to="/portal/customers/subscriptions"
                className="collapse-item"
              >
                Live Chat Subscription
              </Link>
              <Link
                to="/portal/customers/change-Password"
                className="collapse-item"
              >
                Change password
              </Link>
              <Link to="/portal/customers/payments" className="collapse-item">
                Payment history
              </Link>
            </div>
          </div>
        </li> */}

        {/*<!-- Nav Item - Tables -->*/}
        {/* <li className="nav-item">
          <Link to="/portal/logout" className="nav-link">
            <i className="fas fa-fw fa-signout"></i>
            <span>Logout</span>
          </Link>
        </li> */}

        {/*<!-- Nav Item - Charts -->*/}
        {/* <li className="nav-item">
       <div className="nav-link" href="#">
         <i className="fas fa-fw fa-chart-area"></i>
         <span>Charts &amp; Reports</span></a>
     </li> */}

        {/*<!-- Nav Item - Tables -->*/}
        {/* <li className="nav-item">
       <a className="nav-link" href="#">
         <i className="fas fa-fw fa-table"></i>
         <span>Tables</span></a>
     </li> */}

        {/*<!-- Divider -->*/}
        <hr className="sidebar-divider d-none d-md-block" />

        {/*<!-- Sidebar Toggler (Sidebar) -->*/}
        <div className="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
          ></button>
        </div>
      </ul>
      {/*<!-- End of Sidebar -->*/}
    </React.Fragment>
  );
};

export const TopBar = ({ user, customer: cust }) => {
  const customer = { ...cust };
  // customer.alerts = [
  //   { id: 1, date: "10/02/2019", title: "This is first alert" },
  //   { id: 2, date: "10/02/2019", title: "This is second alert" },
  //   { id: 3, date: "10/02/2019", title: "This is third alert" }
  // ];

  // customer.notifications = [
  //   {
  //     id: 1,
  //     date: "10/02/2019",
  //     title: "This is first notification",
  //     sender: "Salman Ahmand"
  //   },
  //   {
  //     id: 2,
  //     date: "10/02/2019",
  //     title: "This is second notification",
  //     sender: "Kashif Mumtaz"
  //   },
  //   {
  //     id: 3,
  //     date: "10/02/2019",
  //     title: "This is third notification",
  //     sender: "Arif Alvi"
  //   }
  // ];

  return (
    <React.Fragment>
      {/*<!-- Topbar -->*/}
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        {/*<!-- Sidebar Toggle (Topbar) -->*/}
        <button
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle mr-3"
        >
          <i className="fa fa-bars"></i>
        </button>

        {/*<!-- Topbar Search -->*/}
        {/* <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
          <div className="input-group">
            <input
              type="text"
              className="form-control bg-light border-0 small"
              placeholder="Search for..."
              aria-label="Search"
              aria-describedby="basic-addon2"
            />
            <div className="input-group-append">
              <button
                className="btn btn-primary"
                type="button"
                style={{
                  height: "33px",
                  paddingTop: "5px"
                }}
              >
                <i className="fas fa-search fa-sm"></i>
              </button>
            </div>
          </div>
        </form> */}

        {/*<!-- Topbar Navbar -->*/}
        <ul className="navbar-nav ml-auto">
          {/*<!-- Nav Item - Search Dropdown (Visible Only XS) -->*/}
          <li className="nav-item dropdown no-arrow d-sm-none">
            <form className="form-inline mr-auto w-100 navbar-search">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control bg-light border-0 small"
                  placeholder="Search for..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                />
                <div className="input-group-append">
                  <button className="btn btn-primary" type="button">
                    <i className="fas fa-search fa-sm"></i>
                  </button>
                </div>
              </div>
            </form>
          </li>

          {/*<!-- Nav Item - Alerts -->*/}
          <li className="nav-item dropdown no-arrow mx-1">
            <span
              className="nav-link dropdown-toggle"
              href="#"
              id="alertsDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-bell fa-fw"></i>
              {/*<!-- Counter - Alerts -->*/}
      <span className="badge badge-danger badge-counter">{customer.alerts === undefined ? '':customer.alerts.length}</span>
            </span>
            {/*<!-- Dropdown - Alerts -->*/}
            <div
              className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="alertsDropdown"
            >
              <h6 className="dropdown-header">Alerts Center</h6>
              {customer.alerts &&
                customer.alerts.map(alert => {
                  return (
                    <Link key={alert.id} to="/portal/customers/alerts">
                      <span
                        className="dropdown-item d-flex align-items-center pointer"
                        href="#"
                      >
                        <div className="mr-3">
                          <div className="icon-circle bg-primary">
                            <i className="fas fa-file-alt text-white"></i>
                          </div>
                        </div>
                        <div>
                          <div className="small text-gray-500">
                            {alert.date}
                          </div>
                          <span className="">{alert.title}</span>
                        </div>
                      </span>
                    </Link>
                  );
                })}

              <span className="dropdown-item text-center small text-gray-500 pointer">
                <Link to="/portal/customers/alerts">Show All Alerts</Link>
              </span>
            </div>
          </li>

          {/*<!-- Nav Item - Messages -->*/}
          <li className="nav-item dropdown no-arrow mx-1">
            <span
              className="nav-link dropdown-toggle"
              href="#"
              id="messagesDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-envelope fa-fw"></i>
              {/*<!-- Counter - Messages -->*/}
<span className="badge badge-danger badge-counter">{customer.notifications === undefined ? '' : customer.notifications.length}</span>
            </span>
            {/*<!-- Dropdown - Messages -->*/}
            <div
              className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="messagesDropdown"
            >
              <h6 className="dropdown-header">Message Center</h6>

              {customer.notifications &&
                customer.notifications.map(notification => {
                  return (
                    <Link
                      key={notification.id}
                      to={`/portal/customers/notifications?id=${notification.id}`}
                      className="dropdown-item d-flex align-items-center"
                      href="#"
                    >
                      <div className="dropdown-list-image mr-3">
                        <img
                          className="rounded-circle"
                          src="https://source.unsplash.com/fn_BT9fwg_E/60x60"
                          alt=""
                        />
                        <div className="status-indicator bg-success"></div>
                      </div>
                      <div className="">
                        <div className="text-truncate">
                          {notification.title}
                        </div>
                        <div className="small text-gray-500">
                          {notification.sender}
                        </div>
                      </div>
                    </Link>
                  );
                })}

              <Link
                to="/portal/customers/notifications"
                className="dropdown-item text-center small text-gray-500"
                href="#"
              >
                Read More Messages
              </Link>
            </div>
          </li>

          <div className="topbar-divider d-none d-sm-block"></div>

          {/*<!-- Nav Item - User Information -->*/}
          <li className="nav-item dropdown no-arrow">
            <span
              className="nav-link dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                {user.fullName}
              </span>
              <img
                className="img-profile rounded-circle"
                src="/Template/img/avatar.png"
                alt=""
              />
            </span>
            {/*<!-- Dropdown - User Information -->*/}
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              <Link
                to="/portal/customers/profile"
                className="dropdown-item"
                href="#"
              >
                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                Profile
              </Link>
              <Link
                to="/portal/customers/change-password"
                className="dropdown-item"
                href="#"
              >
                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                Change password
              </Link>
              {user.role === "Customer" && (
                <React.Fragment>
              <Link
                to="/portal/customers/subscriptions?tab=Purchase%20history"
                className="dropdown-item"
                href="#"
              >
                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                Payment history
              </Link>
              <Link
                to="/portal/customers/subscriptions"
                className="dropdown-item"
                href="#"
              >
                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                Subscriptions
              </Link>
              </React.Fragment>)}
              <div className="dropdown-divider"></div>

              <Link to="/portal/logout" className="dropdown-item">
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Logout
              </Link>
            </div>
          </li>
        </ul>
      </nav>
      {/*<!-- End of Topbar -->*/}
    </React.Fragment>
  );
};

export const PageHeading = ({ heading }) => {
  return (
    <React.Fragment>
      {/*<!-- Page Heading -->*/}
      <div className="row" style={{ display: "block" }}>
        <div className="col-12">
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">{heading}</h1>
            {/* <a
              href="#"
              className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
            >
              <i className="fas fa-download fa-sm text-white-50"></i> Generate
              Report
            </a> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export const Footer = () => {
  return (
    <React.Fragment>
      {/*<!-- Footer -->*/}
      <footer className="sticky-footer bg-white">
        <div className="container my-auto">
          <div className="copyright text-center my-auto">
            <span>Copyright &copy; Lingmo International 2019</span>
          </div>
        </div>
      </footer>
      {/*<!-- End of Footer -->*/}
    </React.Fragment>
  );
};
