import React from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import Form from "Components/Common/form";
import customerService from "Services/customerService";

class CustomerForm extends Form {
  state = {
    data: {
      customerId: "0",
      customerName: "",
      password: "",
      email: "",
      username: "",
      phone1: "",
      phone2: "",
      dateAdded: "",
      subscriptionId: 0
    },
    subscriptionTypes: [],
    errors: {},
    isAdmin: false,
    customerToEdit: null
  };

  validationSchema = {
    customerId: Joi.number(),
    customerName: Joi.string()
      .min(3)
      .max(50)
      .required()
      .label("Customer Name"),
    password: Joi.string()
      .min(6)
      .max(16)
      .label("Password")
      .regex(/^[a-zA-Z0-9~!@#$%^&*]{6,16}$/)
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case "any.empty":
              err.message = "You cannot skip password";
              break;
            case "string.min":
              err.message = `Password should have at least ${err.context.limit} characters!`;
              break;
            case "string.max":
              err.message = `Password should have at most ${err.context.limit} characters!`;
              break;
            default:
              err.message = "Your password contains invalid characters";
              break;
          }
        });
        return errors;
      }),
    email: Joi.string()
      .example("test@test.com")
      .email()
      .required()
      .label("Email"),
    username: Joi.string()
      .label("Username")
      .optional()
      .allow(""),
    phone1: Joi.string()
      .required()
      .label("Phone1"),
    phone2: Joi.string()
      .label("Phone2")
      .optional()
      .allow(""),
    dateAdded: Joi.date()
      .label("Date Added")
      .optional()
      .allow(""),
    subscriptionId: Joi.number()
      .label("Subscription")
      .optional()
      .allow(null)
  };

  async componentDidMount() {
    const subscriptionTypes = await this.loadSubscriptionTypes();
    //const { data } = await customerService.get(id);
    this.setState({
      subscriptionTypes
    });

    if (this.props.customerToEdit) {
      const {
        customerId,
        customerName,
        password,
        email,
        phone1,
        activeSubscription
      } = this.props.customerToEdit;

      const customerFields = {
        customerId,
        customerName,
        password,
        email,
        phone1,
        subscriptionId: activeSubscription
          ? activeSubscription.subscriptionId
          : 0
      };

      this.setState({
        data: customerFields,
        customerToEdit: this.props.customerToEdit
      });
    }
  }

  async loadSubscriptionTypes() {
    const {
      data,
      status,
      message
    } = await customerService.getSubscriptionTypes();
    if (!status) toast.error(message);

    return data;
  }
  doSubmit = async () => {

    let st = "";
    let msg = "";

    const customer = this.state.data;
    let { customerToEdit } = this.state;

    if (customerToEdit === null || customerToEdit === undefined)
      customerToEdit = customer; //it's a new customer record

    customerToEdit.customerName = customer.customerName;
    customerToEdit.password = customer.password;
    customerToEdit.email = customer.email;
    customerToEdit.phone1 = customer.phone1;
    customerToEdit.requestedSubscriptionId = +customer.subscriptionId;
 
    if (customerToEdit.customerId > 0) {
      const { data } = await customerService.update(customerToEdit);
      st = data.status;
      msg = data.message;
    } else {
      const { data } = await customerService.create(customerToEdit);
      st = data.status;
      msg = data.message;
    }

    //Logger.info(data);
    if (st) {
      toast.success(msg);
      const { onClose } = this.props;
      onClose();
    } else {
      toast.error(msg);

      const errors = { ...this.state.errors };
      errors.email = msg;
      this.setState({ errors });
    }
  };

  doCancel = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    return (
      <React.Fragment>
        <div className="">
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                {this.renderInput("customerName", "Customer Name")}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                {this.renderInput("email", "Email")}
              </div>

              <div className="col-md-6">
                {this.state.customerToEdit === null &&
                  this.renderInput("password", "Password")}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                {" "}
                {this.renderInput("phone1", "Phone")}
              </div>
              <div className="col-md-6">
                {this.renderSelect(
                  "subscriptionId",
                  "Subscription",
                  "title",
                  "subscriptionId",
                  this.state.subscriptionTypes,
                  this.state.data.subscriptionId
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-8">
                <div className="form-group">
                  {this.renderButton(
                    "Register",
                    "btn btn-primary",
                    this.handleSubmit,
                    "submit",
                    true
                  )}
                  <span style={{ minWidth: "30px" }}>&nbsp;</span>
                  {this.renderButton("Cancel", "btn", this.doCancel)}
                </div>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default CustomerForm;
