import React, { Component } from "react";
import Table from "Components/Common/table";
import Active from "Components/Common/active";
import IconLink from "Components/Common/create";

class AgentTable extends Component {
  columns = [
    { path: "clientUsername", label: "Agent" },
    { path: "fullname", label: "Name" },
    { path: "email", label: "Email" },
    { path: "phone", label: "Phone" },
    { path: "companyTitle", label: "Website" },
    { path: "customerName", label: "Customer" },
    {
      key: "active",
      path: "Active",
      label: "Active",
      content: agent => (
        <Active
          active={agent.active}
          onClick={() => this.props.onActive(agent)}
        />
      )
    },
    {
      key: "edit",
      path: "",
      label: "Edit",
      content: agent => (
        <IconLink
          onClick={() => this.props.onEdit(agent)}
          className="fas fa-edit"
        />
      )
    },
    {
      key: "Chats",
      path: "",
      label: "Conversations",
      content: agent => (
        <React.Fragment>
          <IconLink
            onClick={() => this.props.onViewChats(agent)}
            className="fas fa-tasks"
          />
        </React.Fragment>
      )
    },
    {
      key: "delete",
      path: "",
      label: "Delete",
      content: agent => (
        <IconLink
          onClick={() => this.props.onDelete(agent)}
          className="fas fa-trash-alt"
        />
      )
    }
  ];

  render() {
    const { agents, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={agents}
        sortColumn={sortColumn}
        onSort={onSort}
        keyField={"userId"}
      />
    );
  }
}

export default AgentTable;
