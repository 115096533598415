import React from "react";
import { Link } from "react-router-dom";

const Packages = () => {
  return (
    <div>
      <table
        class="table"
        style={{
          marginTop: "100px"
        }}
      >
        <tbody>
          <tr>
            <td colspan="4">
              <Link to="/home/register">
                <button className="btn btn-primary">Start now</button>
              </Link>
            </td>
          </tr>
          <tr>
            <th width="30%">&nbsp;</th>
            <th width="23.3%">
              <h3>Basic</h3>{" "}
            </th>
            <th width="23.3%">
              <h3>Platinum</h3>{" "}
            </th>
            <th width="23.3%">
              <h3>Enterprise</h3>{" "}
            </th>
          </tr>
          <tr>
            <td className="alignleft">Plugin </td>
            <td>✓ </td>
            <td>✓ </td>
            <td>✓ </td>
          </tr>
          <tr>
            <td className="alignleft">API </td>
            <td>&nbsp; </td>
            <td>✓ </td>
            <td>✓ </td>
          </tr>

          <tr>
            <td className="alignleft">
              Billed annually (per agent per month){" "}
            </td>
            <td>$285 </td>
            <td>$240 </td>
            <td>Contact us</td>
          </tr>
          <tr>
            <td colspan="4">
              <h3>Lingmo Live Chat in USD</h3>
            </td>
          </tr>
          <tr>
            <td className="alignleft">Annual License Fee</td>
            <td>$0</td>
            <td>$25,000</td>
            <td>$0</td>
          </tr>
          <tr>
            <td className="alignleft">Total Agents per company</td>
            <td>200</td>
            <td>300</td>
            <td>Unlimited</td>
          </tr>
          <tr>
            <td colspan="4">
              <h3>Realtime Translation</h3>
            </td>
          </tr>
          <tr>
            <td className="alignleft">Access to 80 Languages</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Chat & type in own native language</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Visitor language selection</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Agent language selection</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">AI contextual translation</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">
              Specific Industry terminology training
            </td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td colspan="4">
              <h3>Chat System</h3>
            </td>
          </tr>
          <tr>
            <td className="alignleft">Chat between different languages</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Chat notifications</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Chat history</td>
            <td>90 days</td>
            <td>Unlimited</td>
            <td>Unlimited</td>
          </tr>
          <tr>
            <td className="alignleft">Multiple Consective Chats</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Multiple Website Integration</td>
            <td></td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Visitor blocking</td>
            <td></td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">File shareing & sending</td>
            <td></td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Offline Notifications</td>
            <td>Standard</td>
            <td>Customisable</td>
            <td>Customisable</td>
          </tr>
          <tr>
            <td className="alignleft">Chat Transfer</td>
            <td></td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td colspan="4">
              <h3>Lingmo Support</h3>
            </td>
          </tr>
          <tr>
            <td className="alignleft">Multilingual Product FAQs</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Self Service Support & Knowledge Base</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Email Support in 80 languages</td>
            <td></td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Support in 80 languages</td>
            <td></td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Product Manager</td>
            <td></td>
            <td></td>
            <td>✓</td>
          </tr>
          <td colspan="4">
            <h3>Technology Platform</h3>
          </td>
          <tr>
            <td className="alignleft">Lingmo Live Chat Plugin</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Lingmo Translation Restful API</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">API Integration</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">iOS & Android capability</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Multiplatform</td>
            <td></td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">
              Chatboot (Powered by Lingmo Translation API)
            </td>
            <td></td>
            <td>✓</td>
            <td>✓</td>
          </tr>

          <td colspan="4">
            <h3>Management Portal</h3>
          </td>
          <tr>
            <td className="alignleft">Agent Supervision</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Agent rating</td>
            <td>Standard</td>
            <td>Customisable</td>
            <td>Customisable</td>
          </tr>
          <tr>
            <td className="alignleft">Chat Insights</td>
            <td></td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Realtime Dashboard</td>
            <td></td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Account Manager</td>
            <td></td>
            <td></td>
            <td>✓</td>
          </tr>

          <td colspan="4">
            <h3>Agent Window</h3>
          </td>
          <tr>
            <td className="alignleft">Access to 80 languages</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Access to 80 languages</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Multi-Chat Ticketing</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Company Brand & Theme</td>
            <td></td>
            <td>Customisable</td>
            <td>Customisable</td>
          </tr>

          <td colspan="4">
            <h3>Online Visitor Chat Window</h3>
          </td>
          <tr>
            <td className="alignleft">Access to 80 languages</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Company Brand & Theme</td>
            <td></td>
            <td>Customisable</td>
            <td>Customisable</td>
          </tr>
          <tr>
            <td className="alignleft">White Label</td>
            <td></td>
            <td>Customisable</td>
            <td>Customisable</td>
          </tr>

          <td colspan="4">
            <h3>Analytics & Reporting</h3>
          </td>
          <tr>
            <td className="alignleft">Chat Analytics</td>
            <td></td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Agent Analytics</td>
            <td></td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Customised Analytics</td>
            <td></td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Realtime Analytics (Monthly)</td>
            <td></td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">
              Realtime Daily Analytics & Reporting Summary
            </td>
            <td></td>
            <td></td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Summary Download</td>
            <td></td>
            <td></td>
            <td>✓</td>
          </tr>

          <td colspan="4">
            <h3>Security</h3>
          </td>
          <tr>
            <td className="alignleft">SSL Encryption</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Two-Factor Authentication</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">256 bit SSL Secure Connection</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Visitor banning</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Encryption</td>
            <td>✓</td>
            <td></td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Advanced Encryption</td>
            <td></td>
            <td></td>
            <td>✓</td>
          </tr>
          <tr>
            <td className="alignleft">Dedicated Server</td>
            <td></td>
            <td></td>
            <td>✓</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Packages;
