import React, { Component } from 'react';
import {Modal, Form, Container, Row, Col, Button, Alert } from 'react-bootstrap';
import Joi from "joi-browser";
import custService from 'Services/customerService';

class Forgot extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            data:{
                email: ""
            },
            showConfirmation: false,
            showError: false
        };
    }
    
    validationSchema = {
        email: Joi.string()
          .required()
          .email()
          .label("Email"),
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({showError: false});
        var status = true;
        for(var attr in this.state.data)
        {
            const {e} = this.validationSchema[attr].validate(this.state.data[attr]);
            if(e !== undefined)
                status = false;
        }

        if(status)
        {
            const { data } = await custService.forgotPassword(this.state.data.email);
            if(data)
                this.setState({showConfirmation:true});
            else
                this.setState({showError: true});
        }
    }
    handleChange = (event) => {
        let n = event.target.name;
        let v = event.target.value;
        this.setState({ data: { [n]:v } });
    }
    handleConfirmationClose = () => 
    {
        this.setState({showConfirmation: false});
    }
    ShowDialog= ({
        show=false,
        handleClose=null,
        Title="Title",
        Message="Message",
        closeTitle="Close"
    }) => {
        return (<Modal 
            show={show} 
            onHide={handleClose}
            dialogClassName="modal-90w"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title>{Title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{Message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>{closeTitle}</Button>
            </Modal.Footer>
        </Modal>);
    }
    render() { 
        return (
        <React.Fragment>
            <header className="content-header text-black">
                <Container fluid={true} className="mt-5">
                    <Row style={{height: 60+'vh'}} className="justify-content-center align-items-center">
                        <Col xl="auto">
                            <Row className="justify-content-md-center">
                                <Col md="auto">
                                    <h2>Forgotten your password?</h2>
                                    <br />
                                </Col>
                            </Row>
                            <Row hidden={!this.state.showError}>
                                <Col>
                                    <Alert variant="danger">
                                        There is no account registered to this email address. Please try again
                                    </Alert>
                                </Col>
                            </Row>
                            <Row className="justify-content-md-center">
                                <Col md="auto">
                                    <Form onSubmit={this.handleSubmit}>
                                        <Form.Group>
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control name="email" type="email" placeholder="Enter email" onChange={this.handleChange} />
                                            <Form.Text className="text-muted">Please enter the email address you used to register. We will send you a new password</Form.Text>
                                        </Form.Group>
                                        <Button variant="primary" type="submit">Retrieve Password</Button>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </header>
           
            <this.ShowDialog 
                show={this.state.showConfirmation} 
                handleClose={this.handleConfirmationClose} 
                Title="Please check your account"
                Message="A link to reset your password has been sent to your email address"
                />
            
        </React.Fragment>
        );
    }
}
 
export default Forgot;