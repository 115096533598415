import React from "react";

const Active = props => {
  let classes = "fas fa-toggle";
  classes += props.active ? "-on green" : "-off red";
  return (
    <React.Fragment>
      <i
        onClick={props.onClick}
        style={{ cursor: "pointer" }}
        className={classes}
      />
    </React.Fragment>
  );
};

export default Active;
