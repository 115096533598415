/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import Joi from "joi-browser";
import { Input, Hidden } from "./input";
import Select from "./select";
import Checkbox from "./checkbox";

class Form extends Component {
  state = {
    data: {},
    errors: {}
  };

  validate = () => {
    const options = { abortEarly: false, allowUnknown: true };

    const result = Joi.validate(
      this.state.data,
      this.validationSchema,
      options
    );

    if (!result.error) return null;

    const errors = {};
    // for (let item of result.error.details) {
    //   errors[item.path[0]] = item.message;
    // }
    result.error.details.forEach(item => {
      return (errors[item.path[0]] = item.message);
    });

    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.validationSchema[name] };
    const options = { abortEarly: true };
    const { error } = Joi.validate(obj, schema, options);

    return error ? error.details[0].message : null;
  };

  handleSubmit = e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      console.log(errors);
      return;
    }
    if (this.doSubmit !== undefined) this.doSubmit();
    //it must be there in child (extended) component
    else console.log("doSubmit method is missing.");
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };

    if (input.type === "checkbox") {
      data[input.name] = input.checked;
    } else {
      data[input.name] = input.value;
    }
    this.setState({ data, errors });
  };

  renderInput(name, label, type = "text", placeholder = "") {
    const { data, errors } = this.state;
    return (
      <Input
        name={name}
        error={errors[name]}
        value={data[name]}
        type={type}
        placeholder={placeholder}
        onChange={this.handleChange}
        className="form-control login-control"
        label={label}
      />
    );
  }

  renderHidden(name, value) {
    return <Hidden name={name} value={value} />;
  }

  renderSelect(
    name,
    label,
    textProperty,
    valueProperty,
    options,
    defaultValue,
    onChange
  ) {
    const { data, errors } = this.state;

    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        textProperty={textProperty}
        valueProperty={valueProperty}
        options={options}
        defaultValue={defaultValue}
        onChange={onChange ? onChange : this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderCheckbox(name, label, className, onClick) {
    const { data, errors } = this.state;
    return (
      <Checkbox
        name={name}
        label={label}
        value={data[name]}
        className={className}
        onClick={onClick}
        onChange={this.handleChange}
      />
    );
  }

  renderButton(
    label,
    className,
    onClick,
    type = "button",
    checkValidation = false
  ) {
    return (
      <input
        type={type}
        value={label}
        className={className}
        //disabled={checkValidation && this.validate()}
        onClick={onClick}
      />
    );
  }
}

export default Form;
