import React, { Component } from "react";
import _ from "lodash";
import Tabs from "Components/Common/tabs";

import customerService from "Services/customerService";
import authService from "Services/authService";
import { toast } from "react-toastify";
import SubscriptionHistory from "./Subscriptions/history";
import Upgrade from "./Subscriptions/upgrade";
import ActiveSubscription from "./Subscriptions/active-subscription";
import { parseQueryString } from "Utils/util";
require("Components/Common/tabs.css");

class CustomerSubscriptions extends Component {
  state = {
    customer: {},
    activeSubscription: {},
    activeTab: ""
  };

  constructor(props) {
    super(props);
    const qParams = parseQueryString(this.props);
    const aTab = qParams.tab;
    this.state.activeTab = aTab;
  }
  
  async componentDidMount() {
    const user = authService.getCurrentUser();
    if (user) {
      const { data: customer, status, message } = await customerService.get(
        user.id
      );
      
      if (status) {
        const activeSubscription = _.head(
          _.filter(customer.customerSubscriptions, {
            active: true
          })
        );

        this.setState({ customer, activeSubscription });
      } else {
        toast.error(message);
      }
    }
  }
  SetTab = (tab) => {
    this.setState({activeTab: tab});
  }
  onSuccess = () => {
    this.SetTab("Current Subscription");
  }
  render() {
    const { customer, activeSubscription } = this.state;

    return (
      <React.Fragment>
        <Tabs activeTab={this.state.activeTab}>
          <div label="Current Subscription">
            <ActiveSubscription subscription={activeSubscription} />
          </div>
          <div label="Purchase history">
            <SubscriptionHistory
              subscriptions={customer.customerSubscriptions}
            />
          </div>
          <div label="Upgrade">
            <Upgrade activeSubscription={activeSubscription} onSuccess={this.onSuccess} />
          </div>
        </Tabs>
      </React.Fragment>
    );
  }
}

export default CustomerSubscriptions;
