import React from 'react';
import customerService from 'Services/customerService';
import { toast } from 'react-toastify';
import { Component } from 'react';

class ProfileForm extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            data: {
                customerId: 0,
                email: "",
                name: "",
                firstPhone: "",
                secondPhone: "",
                displayRole: "Customer",
            }
        };
    }
    UNSAFE_componentWillReceiveProps(newProps){
        this.setState({ data : newProps.recordToEdit });
    }

    handleChange = (e)=>{
        const data = this.state.data;
        data[e.target.name] = e.target.value;
        this.setState({data:data});
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        let status = "";
        let message = "";
    
        const { data: customer } = this.state;
    
        if (customer.customerId > 0) {
          const resS = await customerService.updateInfo(customer);
          status = resS.data.status;
          message = resS.data.message;
        }
    
        if (status) {
          toast.success(message);
        } else {
          toast.error(message);
        }
    };
    render() {
        if (this.state.data === undefined)
            return (<React.Fragment>
                <div>Loading profile...</div>
            </React.Fragment>);
        return ( <React.Fragment>
            <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                    <label htmlFor="txtID">Customer ID</label>
                    <input id="txtID" className="form-control-plaintext" type="text" value={this.state.data.customerId || 0} readOnly />
                </div>
                <div className="form-group">
                    <label htmlFor="txtEmail">Email</label>
                    <input id="txtEmail" className="form-control-plaintext" type="text" value={this.state.data.email || ''} readOnly />
                </div>
                <div className="form-group">
                    <label htmlFor="customerName">Name</label>
                    <input id="customerName"
                            name="customerName" 
                            className="form-control" 
                            type="text" 
                            placeholder="i.e. John Smith" 
                            value={this.state.data.customerName || ''} 
                            onChange={this.handleChange}
                            />
                </div>
                <div className="form-group">
                    <label htmlFor="firstPhone">Phone 1</label>
                    <input id="firstPhone" 
                        className="form-control" 
                        type="text" 
                        placeholder="i.e. phone 1"
                        name="firstPhone" 
                        value={this.state.data.firstPhone || ''} 
                        onChange={this.handleChange}
                        />
                </div>
                <div className="form-group">
                    <label htmlFor="secondPhone">Phone 2</label>
                    <input id="secondPhone" 
                        className="form-control" 
                        type="text" 
                        placeholder="i.e. phone 2"
                        name="secondPhone" 
                        value={this.state.data.secondPhone || ''}
                        onChange={this.handleChange}
                        />
                </div>
                <div className="form-group">
                    <label htmlFor="txtRole">Role</label>
                    <input id="txtRole" className="form-control-plaintext" type="text" value={this.state.data.displayRole || ''} readOnly />
                </div>
                <button type="submit" className="btn btn-primary mb-2">Save Changes</button>
            </form>
        </React.Fragment> );
    }
}
 
export default ProfileForm;