import http from "./httpService";
import ApiHelper from "Utils/apiHelper";
import Logger from "./logService";
export async function getAll() {
  const { data: response } = await http
    .get("/agents")
    .then(res => {
      return res;
    })
    .catch(err => {
      Logger.error(err);
    });

  return response;
}

export async function get(id) {
  const { data: response } = await http
    .get(`/agents/${id}`)
    .then(res => {
      return res;
    })
    .catch(err => {
      Logger.error(err);
      return ApiHelper.badResponse(null, false, err);
    });

  return response;
}
export async function create(agent) {
  const url = "/agents";

  const postData = agent;

  try {
    const { data: response } = await http.post(url, postData);
    if (response) return response;

    throw new Error("No response from server");
  } catch (ex) {
    let msg = "Server Error";
    Logger.error(ex);
    if (ex.response && ex.response.status === 404) {
      msg = "Errors while creating of new agent";
    }
    return ApiHelper.badResponse(null, false, msg);
  }
}

export async function update(agent) {
  const url = "/agents";

  const postData = agent;

  try {
    const { data: response } = await http.put(url, postData);
    if (response) return response;

    throw new Error("No response from server");
  } catch (ex) {
    let msg = "Server Error";
    if (ex.response && ex.response.status === 404) {
      msg = "Errors while updating agent information." + ex.response;
    }
    Logger.error(ex);
    return ApiHelper.badResponse(null, false, msg);
  }
}

export async function remove(id) {
  const url = `/agents/${id}`;

  const { data: response } = await http
    .delete(url)
    .then(res => {
      return res;
    })
    .catch(err => {
      Logger.error(err);
      return ApiHelper.badResponse(null, false, err);
    });

  return response;
}

export async function getWebsiteAgents(websiteId) {
  const { data: response } = await http
    .get(`/agents/website/${websiteId}`)
    .then(res => {
      return res;
    })
    .catch(err => {
      Logger.error(err);
    });

  return response;
}

export async function getCustomerAgents(customerId) {
  const { data: response } = await http
    .get(`/agents/customer/${customerId}`)
    .then(res => {
      return res;
    })
    .catch(err => {
      Logger.error(err);
    });

  return response;
}

export default {
  getAll,
  get,
  create,
  update,
  remove,
  getWebsiteAgents,
  getCustomerAgents
};
