import { Chart } from "react-google-charts";

import React from "react";


const options = {
    colors: ['#1d76bc', '#636a68']
  };

  class LineChart extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className={`col-xl-${this.props.size} col-lg-${this.props.size}`}>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 className="m-0 font-weight-bold text-primary">
                                {this.props.title}
                            </h6>
                        </div>
                        <div className="card-body">
                            <div>
                            <Chart
                                chartType="ColumnChart"
                                loader={<div>Loading Chart</div>}
                                width="100%"
                                height="400px"
                                data={this.props.data}
                                options={options}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default LineChart;
