import React, { Component } from 'react';
import Chart from 'react-google-charts';

class MonthlyWebsites extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
        <React.Fragment>
                <div className={`col-xl-${this.props.size} col-lg-${this.props.size}`}>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 className="m-0 font-weight-bold text-primary">
                                {this.props.title}
                            </h6>
                        </div>
                        <div className="card-body">
                            <div>
                            <Chart
                                width={'100%'}
                                height={'400px'}
                                chartType="Bar"
                                loader={<div>Loading Chart</div>}
                                data={this.props.data}
                                options={{
                                    title: 'Tickets distribution website wise',
                                    chartArea: {width: '50%'},
                                    hAxis: { title: 'Months'},
                                    vAxis: { title: 'Tickets' }
                                }}
                            />
                            </div>
                        </div>
                    </div>
                </div>
        </React.Fragment> 
        );
    }
}

class WebsiteLanguages extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <React.Fragment>
            <div className={`col-xl-${this.props.size} col-lg-${this.props.size}`}>
                <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 font-weight-bold text-primary">
                            {this.props.title}
                        </h6>
                    </div>
                    <div className="card-body">
                        <div>
                        <Chart
                            width={'100%'}
                            height={'400px'}
                            chartType="Bar"
                            loader={<div>Loading Chart</div>}
                            data={this.props.data}
                            options={{
                                title: 'Language distribution website wise',
                                isStacked: true,
                                chartArea: {width: '50%'},
                                hAxis: { title: 'Months'},
                                vAxis: { title: 'Tickets' }
                            }}
                        />
                        </div>
                    </div>
                </div>
            </div>
            </React.Fragment> 
         );
    }
}
 
export {WebsiteLanguages, MonthlyWebsites };