import React from "react";
const IconLink = props => {
  let classes = props.className;
  //classes += props.active ? "-on green" : "-off red";
  return (
    <React.Fragment>
      <i
        onClick={props.onClick}
        style={{ cursor: "pointer" }}
        className={classes}
      />
    </React.Fragment>
  );
};

export default IconLink;
