import React, { Component } from "react";
import Table from "Components/Common/table";
import Active from "Components/Common/active";
import IconLink from "Components/Common/create";

class CustomerTable extends Component {
  columns = [
    { path: "customerId", label: "Id" },
    { path: "username", label: "Username" },
    { path: "customerName", label: "Name" },
    { path: "phone1", label: "Phone" },
    { path: "activeSubscription.title", label: "Subscription" },
    {
      key: "active",
      path: "active",
      label: "Active",
      content: customer => (
        <Active
          active={customer.active}
          onClick={() => this.props.onActive(customer)}
        />
      )
    },
    {
      key: "edit",
      path: "",
      label: "Edit",
      content: customer => (
        <IconLink
          onClick={() => this.props.onEdit(customer)}
          className="fas fa-user-edit"
        />
      )
    },

    {
      key: "addWebsite",
      path: "",
      label: "Websites",
      content: customer => (
        <React.Fragment>
          <IconLink
            onClick={() => this.props.onViewWebsites(customer)}
            className="far fa-folder-open"
          />{" "}
          <IconLink
            onClick={() => this.props.onAddWebsite(customer)}
            className="fas fa-folder-plus"
          />
        </React.Fragment>
      )
    },
    {
      key: "Agents",
      path: "",
      label: "Agents",
      content: customer => (
        <React.Fragment>
          <IconLink
            onClick={() => this.props.onViewAgents(customer)}
            className="fas fa-users"
          />
        </React.Fragment>
      )
    }

    // {
    //   key: "delete",
    //   content: customer => (
    //     <button
    //       onClick={() => this.props.onDelete(customer)}
    //       className="btn btn-danger btn-sm"
    //     >
    //       Delete
    //     </button>
    //   )
    // }
  ];

  render() {
    const { customers, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={customers}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default CustomerTable;
