import React, { Component } from "react";
import { PageHeading } from "./portalLayout";
import {
  DashboardCard
} from "./dashboardLayout";

import { getSummary } from "Services/analyticsService";
import LineChart from "./Admin/Components/line-chart";
import PieChart from "./Admin/Components/pie-chart";
import { toast } from "react-toastify";
import { getCurrentUser } from "Services/authService";
import Moment from "react-moment";
class Dashboard extends Component {
  constructor(props){
    super(props);
    this.state = {
      CompletedChats: 0,
      ActiveChats: 0,
      AgentsOnline: 0,
      CustomerWaiting: 0,
      MonthlyChats: [],
      LanguagesUsed: [],
      SubscriptionType: "",
      SubscriptionExpiresAt: null
    };
  }
  async loadSummary(customerId){
    const {data, status, message} = await getSummary(customerId);
    if (!status) toast.error(message);
    return data;
  }
  async componentDidMount(){
    try{
      const user = await getCurrentUser();
      
      console.log(user);
      if(user){
        const data = await this.loadSummary(user.id);

        this.setState({
          CompletedChats: data.completedChats,
          ActiveChats: data.activeChats,
          AgentsOnline: data.agentsOnline,
          CustomerWaiting: data.customerWaiting,
          MonthlyChats: data.monthlyChats.data,
          LanguagesUsed: data.languagesUsed.data,
          SubscriptionType: user.subscription,
          SubscriptionExpiresAt: user.expiresAt
        });  
      }
    } catch (ex) {
      toast.error(ex.message);
    }
  }

  render(){
    const today = new Date();
    const expiresAt = new Date(this.state.SubscriptionExpiresAt);
    let subscriptionNotificaiton;
    if(this.state.SubscriptionExpiresAt === null) subscriptionNotificaiton = null;
    else if ( expiresAt < today) {
      subscriptionNotificaiton = <div className="alert alert-danger" role="alert">
      Your <a href="/portal/customers/subscriptions"><u>{this.state.SubscriptionType}</u></a> subscription has been exipred <b><Moment fromNow>{expiresAt}</Moment></b>
    </div>
    }
    else {
      subscriptionNotificaiton = <div className="alert alert-success" role="alert">
      Your <a href="/portal/customers/subscriptions"><u>{this.state.SubscriptionType}</u></a> subscription is going to exipre <b><Moment fromNow>{expiresAt}</Moment></b>
    </div>
    }
    return (
      <React.Fragment>
        <PageHeading heading="Dashboard" />        
        <div className="row">
          <div className="col-md-12">
            {subscriptionNotificaiton}
          </div>
        </div>
        <div className="row">
          <DashboardCard
            title="Completed Chats"
            value={this.state.CompletedChats}
            colorClass="primary"
            icon="dollar-sign"
          />
          <DashboardCard
            title="Agents Online"
            value={this.state.AgentsOnline}
            colorClass="success"
            icon="comments"
          />
          <DashboardCard
            title="Active Chats"
            value={this.state.ActiveChats}
            colorClass="info"
            icon="clipboard-list"
          />
          <DashboardCard
            title="Customers Waiting"
            value={this.state.CustomerWaiting}
            colorClass="warning"
            icon="calendar"
          />
        </div>

        <div className="row">
          <LineChart 
          title="Monthly Chats Overview"
          data={this.state.MonthlyChats} 
          size={8}
          />
          <PieChart 
          title="Commonly used Languages"
          data={this.state.LanguagesUsed} 
          size={4}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
