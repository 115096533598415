import React, { Component } from "react";
import Table from "Components/Common/table";
import Active from "Components/Common/active";
import IconLink from "Components/Common/create";

class WebsiteTable extends Component {
  columns = [
    { path: "companyId", label: "Company Id" },
    { path: "customerEmail", label: "Customer account" },
    { path: "title", label: "Website title" },
    { path: "url", label: "Url" },
    { path: "totalConversations", label: "Total Chats" },
    { path: "totalAgents", label: "Agents" },
    { 
      key:"script",
      path: "", 
      label: "Script",
      content: website => (
        <IconLink
          onClick={() => this.props.onOpenScript(website)}
          className="fab fa-js-square"
        />
      ) 
    },
    {
      key: "demo",
      path: "accessSecret",
      label: "Demo",
      content: website => (
        <IconLink
          onClick={() => this.props.onOpenDemo(website)}
          className="fas fa-desktop"
        />
      )
    },
    {
      key: "active",
      path: "isActive",
      label: "Active",
      content: website => (
        <Active
          active={website.isActive}
          onClick={() => this.props.onActive(website)}
        />
      )
    },
    {
      key: "edit",
      path: "",
      label: "Edit",
      content: website => (
        <IconLink
          onClick={() => this.props.onEdit(website)}
          className="fas fa-edit"
        />
      )
    },
    {
      key: "Agent",
      path: "",
      label: "Agents",
      content: website => (
        <React.Fragment>
          <IconLink
            onClick={() => this.props.onNewAgent(website)}
            className="fas fa-user-plus"
          />
          &nbsp;&nbsp;&nbsp;
          <IconLink
            onClick={() => this.props.onViewAgents(website)}
            className="fas fa-users"
          />
        </React.Fragment>
      )
    },
    {
      key: "Chats",
      path: "",
      label: "Chats",
      content: website => (
        <React.Fragment>
          <React.Fragment>
            <IconLink
              onClick={() => this.props.onViewChats(website)}
              className="fas fa-tasks"
            />
          </React.Fragment>
        </React.Fragment>
      )
    },
    {
      key: "delete",
      path: "",
      label: "Delete",
      content: website => (
        <span style={{ color: "#ff5555" }}>
          <IconLink
            onClick={() => this.props.onDelete(website)}
            className="alert fas fa-trash-alt"
          />
        </span>
      )
    }
  ];

  render() {
    const { websites, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={websites}
        sortColumn={sortColumn}
        onSort={onSort}
        keyField="companyId"
      />
    );
  }
}

export default WebsiteTable;
