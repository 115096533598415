import React , {Component} from 'react';
import PaymentOptions from 'Portal/Payment/PaymentOptions';
import PayPalInfo from 'Portal/Payment/PayPalInfo';
import PinPaymentsInfo from 'Portal/Payment/PinPaymentsInfo';
import SelectSubscriptionTypes from 'Components/Custom/SelectSubscriptionTypes';

class UpgradeForm extends Component {
    constructor(props) {
        super(props);
        this.state = { payment: 'none', amount: 0 , subscription: 0}
    }
    PaypalSelected = () => {
        this.setState({payment: 'paypal'});
    }
    CreditCardSelected = () => {
        this.setState({payment: 'creditcard'});
    }
    UpdateAmount = (e) => {
        var amt = e.data.amount;
        var subs = e.data.subscriptionId;
        this.setState( { amount: amt , subscription: subs} );
    }
    goto = () => {
        window.location = "/portal/customers/subscriptions?tab=Current Subscription";
      };
    render() { 
        return ( 
        <React.Fragment>
            <SelectSubscriptionTypes 
            name="subscription" 
            label="Subscription" 
            defaultValue={this.props.activeSubscription.subscriptionId} 
            onChange={this.UpdateAmount} 
            />
            
            <PaymentOptions PaypalSelected={this.PaypalSelected} CreditCardSelected={this.CreditCardSelected}></PaymentOptions>
            {this.state.payment === 'paypal' ? <PayPalInfo amount={this.state.amount} subscription={this.state.subscription} onSuccess={this.goto} /> : null}
            {this.state.payment === 'creditcard'? <PinPaymentsInfo amount={this.state.amount} /> : null}
        </React.Fragment> 
        );
    }
}
 
export default UpgradeForm;