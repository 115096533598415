import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { changePassword } from 'Services/customerService';
import { getCurrentUser } from "Services/authService";
import { toast } from "react-toastify";


class CustomerChangePassword extends Component {
  state = { 
    customerId: 0,
    password: '',
    newPassword1: '',
    newPassword2: '' 
  };

  async componentDidMount(){
    const user = await getCurrentUser();
    if(user){
      this.setState({ customerId: user.id});
    }
  }
  onSubmit = async (evt) =>{
    evt.preventDefault();
    const { customerId , password, newPassword1, newPassword2 } = this.state;

    if(newPassword1 !== newPassword2)
    {
      toast.error("passwords are not matched");
      return;
    }

    const response = await changePassword(customerId, password, newPassword1, newPassword2);

    if(response.data.status === false)
    {
      toast.error(response.data.message);
    }
    else
    {
      toast.success(response.data.message);
    }
  }
  handleUserInput (e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
  }
  render() {
    return (
      <React.Fragment>
        <div><h3>Change Password</h3></div>
        <Form onSubmit={this.onSubmit}>
          <Form.Group controlId="currentPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" name="password" placeholder="Password" onChange={(event) => this.handleUserInput(event)} />
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control type="password" name="newPassword1" placeholder="New Password" onChange={(event) => this.handleUserInput(event)} />
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>Re-enter New Password</Form.Label>
            <Form.Control type="password" name="newPassword2" placeholder="Re enter new Password" onChange={(event) => this.handleUserInput(event)} />
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </React.Fragment>
    );
  }
}

export default CustomerChangePassword;
