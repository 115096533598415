import React from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import Form from "Components/Common/form";
import Logger from "Services/logService";
import authService from "Services/authService";
import custService from "Services/customerService";
import Loader from "Components/Common/loader";
import { Modal, Button } from "react-bootstrap";

class Registration extends Form {
  state = {
    loading: false,
    data: {
      firstName: "",
      lastName: "",
      password: "",
      email: "",
      phone1: "",
      requestedSubscriptionId: 1,
      subscriptionId: 1,
      company: ""
    },
    subscriptionTypes: [],
    errors: {},
    isAdmin: false,
    showConfirmation: false
  };

  validationSchema = {
    firstName: Joi.string()
      .min(3)
      .max(30)
      .required()
      .label("First Name"),
    lastName: Joi.string()
      .min(3)
      .max(30)
      .required()
      .label("Last Name"),
    password: Joi.string()
      .min(6)
      .max(16)
      .required()
      .label("Password")
      .regex(/^[a-zA-Z0-9~!@#$%^&*]{6,16}$/)
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case "any.empty":
              err.message = "You cannot skip password";
              break;
            case "string.min":
              err.message = `Password should have at least ${err.context.limit} characters!`;
              break;
            case "string.max":
              err.message = `Password should have at most ${err.context.limit} characters!`;
              break;
            default:
              err.message = "You password contains invalid characters";
              break;
          }
        });
        return errors;
      }),
    email: Joi.string()
      .email()
      .required()
      .label("Email"),
    myPhone: Joi.string()
      .required()
      .label("Phone"),
    company: Joi.string()
      .min(3)
      .max(30)
      .required()
      .label("Company"),

    subscriptionId: Joi.number().required()
  };

  async componentDidMount() {
    this.setState({ isAdmin: this.props.isAdmin });

    const subscriptionTypes = await this.loadSubscriptionTypes();
    this.setState({ subscriptionTypes });
  }

  async loadSubscriptionTypes() {
    const { data, status, message } = await custService.getSubscriptionTypes();
    if (!status) toast.error(message);

    //const sTypes = [];
    //sTypes.push({ title: "All" }, ...data);

    return data;
  }
  handleConfirmationClose = () => {
    this.setState({showConfirmation: false});
  }
  handleConfirmationCloseSuccess = () => {
    this.setState({showConfirmation: false});

    authService.loginWithJwt();
    const { state } = this.props.location;

    window.location = state ? state.from.pathname : "/portal";
}
  doSubmit = async () => {

    const postData = this.state.data;

    postData.companies = [
      {
        title: this.state.data.company
      }
    ];

    console.log("Posted data: ",postData);
    const { data: response } = await custService.register(postData);

    if (response.status) {
      Logger.success(response.message);

      if (this.state.isAdmin === true) {
        return;
      }

      this.setState({ showConfirmation: true});
    } else {
      Logger.error(response.message);

      const errors = { ...this.state.errors };
      errors.email = response.message;
      this.setState({ errors });
    }
  };

  doCancel = () => {

  };

  render() {
    if (this.state.loading) return <Loader />;
    // const { data, errors } = this.state;
    return (
      <React.Fragment>
      <header className="content-header">
        <div className="container">
          <div className="fixed-center row">
            <div
              className="col-md-12 mx-auto text-black wow fadeIn"
              style={{ display: "inline-block" }}
            >
              <div className="mt-5">
                <br />
                <h3>Live Chat Registration</h3>
                <div className="">
                  <form onSubmit={this.handleSubmit}>
                    {this.renderInput("firstName", "First Name")}
                    {this.renderInput("lastName", "Last Name")}
                    {this.renderInput("email", "Email")}
                    {this.renderInput("password", "Password", "password")}
                    {this.renderInput("myPhone", "Phone")}
                    {this.renderInput("company", "Company")}

                    <div className="form-group">
                      {this.renderButton(
                        "Register",
                        "btn btn-primary",
                        this.handleSubmit,
                        "submit",
                        true
                      )}
                      <span style={{ minWidth: "30px" }}>&nbsp;</span>
                      {this.renderButton("Cancel", "btn", this.doCancel)}
                    </div>
                  </form>
                </div>
              </div>

              <p className="mt-5" align="center" />
            </div>
          </div>
        </div>
      </header>
      <Modal 
      show={this.state.showConfirmation} 
      onHide={this.handleConfirmationClose}
      dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title>Thank you for registering.<br /> Please verify your email address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>To verify your email address, click on the verification link in the email that you will receive from Lingmo Live Chat.</p>
          <p>If you have not received an email within a couple of minutes, please check your spam/junk folder. You can resend the email by clicking on the button below.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleConfirmationCloseSuccess}>Close</Button>
          <Button variant="primary">Resend Verification Email</Button>
        </Modal.Footer>
      </Modal>
      </React.Fragment>
    );
  }
}

export default Registration;
