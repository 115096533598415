import React from "react";

const Input = ({ name, label, error, ...rest }) => {
  const errorClass = error ? "has-error" : "";
  return (
    <React.Fragment>
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <input name={name} className={errorClass} {...rest} />
        {error && (
          <div className="alert alert-danger login-control">{error}</div>
        )}
      </div>
    </React.Fragment>
  );
};

const Hidden = ({name, value}) => {
  return(<input type="hidden" name={name} value={value} />);
};
export {Input, Hidden};
export default Input ;

