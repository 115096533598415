import React, { Component } from "react";
import { PageHeading } from "Portal/portalLayout";
import qs from 'query-string';
import {WebsiteReport, AgentsReport} from 'Portal/Customers/reportsLayout';

class CustomerReports extends Component {
  state = {};
  render() {
    var q = qs.parse(this.props.location.search);

    return (
      <React.Fragment>
        <PageHeading heading="Charts &amp; Reports" />
        <div>
        {q.type === 'website' ? <WebsiteReport />: null}
        {q.type === 'agents' ? <AgentsReport />: null}
        </div>
      </React.Fragment>
    );
  }
}

export default CustomerReports;
