import React, { Component } from 'react';
import { Alert, Row, Col } from 'react-bootstrap';

function AccountCell(props){
    const item = props.data;

    const dayOfWeeks = [ "Sunday" , "Monday" , "Tuesday" , "Wednesday" , "Thursday" , "Friday" , "Saturday"];
    return <Row>
    <Col>
        <Alert variant="success">
            <Alert.Heading>{item["title"]}</Alert.Heading>
                <Row className="justify-content-between">
                    <Col>
                        Full day active: {item['isFullDay'] ? "Yes" : "No"}
                    </Col>
                    {!item['isFullDay'] && 
                        (
                    <React.Fragment>
                        <Col>
                            Active Time of Day: {item['todfrom']} - {item['todto']} UTC{item['timeZone']}
                        </Col>
                        <Col>
                        Day of weeks: 
                        {dayOfWeeks.map((d,i)=>(item['daysOfWeek']&(1<<i)) === 0 ? "" : " "+d)}
                        </Col>
                    </React.Fragment>)
                }
                    <Col className="text-right">

                        <button type="button" className="btn btn-outline-info" onClick={() => props.onSchedule(props.data)}>Schedule</button>
                    </Col>
                </Row>
            <hr />
            <p className="mb-0">
                <a target="_blank" rel="noopener noreferrer" href={item['url']}>{item['url']}</a>
            </p>
        </Alert>
    </Col>
</Row>;
}
class AccountTable extends Component {
    constructor(props) {
        super(props);
        this.state = { 
        }
    }
    render() {
        const {data} = this.props;

        return ( <React.Fragment>
            {data.map((item,i) => (
                <AccountCell key={i} data={item} onSchedule={this.props.onSchedule} />
            ))}
        </React.Fragment> );
    }
}
 
export default AccountTable;