import React, { Component, Suspense } from "react";
import _ from "lodash";
import { Modal } from "react-bootstrap";

import authService from "Services/authService";
import websiteService from "Services/websiteService";
import agentService from "Services/agentService";
import Pagination from "Components/Common/pagination";
import { toast } from "react-toastify";
import { paginate } from "Utils/paginate";
import ListGroup from "Components/Common/listGroup";
import Logger from "Services/logService";
import { PageHeading } from "../portalLayout";
import Loader from "Components/Common/loader";
import chatService from "Services/chatService";
import { parseQueryString } from "Utils/util";
import ChatView from "Portal/Admin/Components/chat-view";
import ChatTable from "Portal/Admin/Components/chat-table";

class CustomerChats extends Component {
  state = {
    customerId: 0,
    websiteId: 0,
    agentId: 0,
    groupId: 0,
    chats: [],
    languages: [],
    chatMessages: [],
    agents: [],
    selectedAgent: {},
    currentPage: 1,
    pageSize: 10,
    sortColumn: { path: "title", order: "asc" },
    searchKeywords: "",
    subscriptionTypes: [],
    loading: true,
    recordToEdit: null,
    chatModalIsOpen: false,
    websites: [],
    selectedWebsite: {},
  };

  setShow = (modalType, show) => {
    //if (modalType === "customer") this.setState({ cusModalIsOpen: show });
    if (modalType === "chat") this.setState({ chatModalIsOpen: show });
    //if (modalType === "chat") this.setState({ chatModalIsOpen: show });
  };

  openModal = modalType => {
    this.setShow(modalType, true);
  };
  closeModal = modalType => {
    this.setShow(modalType, false);

    this.setState({ recordToEdit: null });

    //this.componentDidMount();
  };

  constructor(props) {
    super(props);
    const qParams = parseQueryString(this.props);

    this.state.searchKeywords = qParams.search === undefined ? "" : qParams.search;
    //this.state.websiteId = +(qParams.websiteId === undefined ? "0" : qParams.websiteId);
    this.state.websiteId = 0;
    this.state.customerId = +(qParams.customerId === undefined ? "0" : qParams.customerId);
    this.state.agentId = +(qParams.agentId === undefined ? "0" : qParams.agentId);
  }

  async componentDidMount() {
    try {
      //console.log("Chats: component did mount: ",this.state);

      const user = authService.getCurrentUser();

      this.setState({ loading: true });
      const chats = await this.loadChats();
      const websites = await this.loadWebsites(this.state.customerId);

      const agents = await this.loadAgents(user.id);

      const { data }  = await chatService.getLanguages();
      const languages = data;

      this.setState({ chats, agents , languages, websites });

      if (this.state.websiteId > 0) {
        const selectedAgent = agents.filter(
          w => w.companyId && w.companyId.toString() === this.state.websiteId
        )[0];
         if(selectedAgent !== undefined)
          this.setState({ selectedAgent });
      }
    } catch (ex) {
      toast.error(ex.message);
    } finally {
      this.setState({ loading: false });
    }
  }

  async loadChats() {
    let { customerId, websiteId, agentId } = this.state;

    if (!customerId > 0) customerId = 0;
    if (!websiteId > 0) websiteId = 0;
    if (!agentId > 0) agentId = 0;

    const { data, status, message } =
      agentId === 0
        ? await chatService.getCustomerChats(customerId, websiteId)
        : await chatService.getAgentChats(agentId);

    if (!status) toast.error(message);
    return data;
  }
  async loadWebsites(customerId) {

    const { data } =
      customerId > 0
        ? await websiteService.getCustomerWebsites(customerId)
        : await websiteService.getAll();

    const websites = [{ title: "All websites", companyId: 0 }, ...data];
    return websites;
  }

  async loadAgents(customerId) {
    const { data } =
      customerId > 0
        ? await agentService.getCustomerAgents(customerId)
        : await agentService.getAll();

    const agents = [{ clientUsername: "All agents", userId: 0, companyId: 0 }, ...data];
    return agents;
  }

  handleLanguageChange = async lang => {
    const { data } = await chatService.getChat(this.state.groupId,lang.target.value);
    this.setState({ chatMessages: data.chatMessages });
  };
  handleAgentSelect = agent => {
    this.setState({ selectedAgent: agent, currentPage: 1 });
  };
  handleWebsiteSelect = website => {
    this.setState({ selectedWebsite: website, currentPage: 1, selectedAgent: {} });
  };
  handleViewChat = async chat => {
    const groupId = chat.groupId;
    this.openModal("chat");
    const { data } = await chatService.getChat(groupId);
    this.setState({ groupId: groupId, chatMessages: data.chatMessages });
  };

  handleEdit = chat => {
  };

  async updateChat(chat) {
  }

  handleDelete = async chat => {
    const originalData = this.state.chats;

    const chats = this.state.chats.filter(c => c.groupId !== chat.groupId);
    this.setState({ chats });

    const { message, status } = await chatService.remove(chat.groupId);
    if (status) toast.success(message);
    else {
      Logger.error(message);
      this.setState({ agents: originalData });
    }
  };

  handleActivity = async chat => {
    //toast("Active link clicked");
    const originalChats = [...this.state.chats];
    const chats = [...this.state.chats];
    const index = chats.indexOf(chat);
    chats[index] = { ...chats[index] };
    chats[index].active = !chats[index].active;
    await this.updateChat(chats[index]).then(result => {
      if (result) {
        toast.success("Selected chat record updated");
        this.setState({ chats });
      } else {
        toast.error("Failed to update agent");
        this.setState({ chats: originalChats });
      }
    });
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleSearch = event => {
    this.setState({ searchKeywords: event.target.value });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      chats: allChats,
      searchKeywords
    } = this.state;

    let filtered = this.state.websiteId
      ? allChats.filter(
          m => m.companyId && +m.companyId === +this.state.websiteId
        )
      : allChats;

    filtered =
      this.state.selectedAgent !== undefined && this.state.selectedAgent.userId !== undefined && this.state.selectedAgent.userId > 0
        ? filtered.filter(
            m => m.repId && m.repId === this.state.selectedAgent.userId
          )
        : filtered;

    filtered = searchKeywords
      ? filtered.filter(
          m =>
            (m.groupName &&
              m.groupName
                .toLowerCase()
                .indexOf(searchKeywords.toLowerCase()) !== -1) ||
            (m.clientFullname &&
              m.clientFullname
                .toLowerCase()
                .indexOf(searchKeywords.toLowerCase()) !== -1) ||
            (m.language &&
              m.language.toLowerCase().indexOf(searchKeywords.toLowerCase()) !==
                -1)
        )
      : filtered;

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const chats = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: chats };
  };

  filterAgents(){
    const agents = this.state.agents;
    const selectedWebsite = this.state.selectedWebsite;
    const filteredAgents = agents===null 
                        || agents===undefined 
                        || selectedWebsite.companyId===undefined 
                        || selectedWebsite.companyId===0 ? agents : agents.filter( a => a.companyId === selectedWebsite.companyId);

    return filteredAgents; 
  }
  render() {
    if (this.state.loading) return <Loader />;

    //let count = 0;
    const { length: count } = this.state.chats;
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedAgent,
      selectedWebsite,
      websites
    } = this.state;

    if (count === 0) return <p>There are no chats for selected agent</p>;

    const { totalCount, data: chats } = this.getPagedData();
    const filteredAgents = this.filterAgents();
    return (
      <React.Fragment>
        <PageHeading heading="Chats" />
        <div className="row">
        <div className="col-2">
        <ListGroup
              items={websites}
              textProperty="title"
              valueProperty="companyId"
              selectedItem={selectedWebsite}
              onItemSelect={this.handleWebsiteSelect}
            />
        </div>
          <div className="col-2">
            <ListGroup
              items={filteredAgents}
              textProperty="clientUsername"
              valueProperty="userId"
              selectedItem={selectedAgent}
              onItemSelect={this.handleAgentSelect}
            />
          </div>

          <div className="col-8">
            <div className="flex-row">
              <div className="flex-col"></div>
              <div className="flex-col-right">
                <input
                  value={this.state.searchKeywords}
                  name="txtSearch"
                  type="text"
                  onChange={this.handleSearch}
                ></input>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p>Showing {totalCount} chats.</p>
                <Suspense fallback={<div>loading...</div>}>
                  <ChatTable
                    chats={chats}
                    sortColumn={sortColumn}
                    onActive={this.handleActivity}
                    onDelete={this.handleDelete}
                    onSort={this.handleSort}
                    onEdit={this.handleEdit}
                    onViewChat={this.handleViewChat}
                  />
                </Suspense>
                <Pagination
                  itemsCount={totalCount}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  onPageChange={this.handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>

        <Modal
          size="lg"
          show={this.state.chatModalIsOpen}
          onHide={() => this.closeModal("chat")}
          dialogClassName="modal-dialog"
        >
          <Modal.Header closeButton>
            <Modal.Title>View Chat</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ChatView
              groupId={this.state.groupId}
              agentId={this.state.agentId}
              data={this.state.chatMessages}
              languages={this.state.languages}
              onLanguageChange={this.handleLanguageChange} 
              onClose={() => this.closeModal("chat")}
            />
          </Modal.Body>
        </Modal>
        {/* <Modal
          size="lg"
          show={this.state.chatsModalIsOpen}
          onHide={() => this.closeModal("agent")}
          dialogClassName="modal-dialog"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add new Agent</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AgentForm
              website={this.state.recordToEdit}
              recordToEdit={null}
              onClose={() => this.closeModal("agent")}
            />
          </Modal.Body>
        </Modal> */}
      </React.Fragment>
    );
  }
}

export default CustomerChats;
