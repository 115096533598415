import React from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import Form from "Components/Common/form";
import agentService from "Services/agentService";

class AgentForm extends Form {
  state = {
    data: {
      companyId: 0,
      userType: 2,
      username: "",
      clientUsername: "",
      fullname: "",
      password: "",
      languageId: "en-US",
      phone: "",
      email: "",
      active: false
    },
    errors: {},
    isAdmin: false,
    recordToEdit: null,
    website: {}
  };

  validationSchema = {
    companyId: Joi.number(),
    userType: Joi.number(),
    username: Joi.string()
      .required()
      .max(100)
      .min(3)
      .label("Login name"),
    fullname: Joi.string()
      .required()
      .max(100)
      .min(3)
      .label("Agent name"),
    email: Joi.string()
      .required()
      .max(100)
      .min(3)
      .label("Email"),
    phone: Joi.string()
      .required()
      .max(50)
      .min(3)
      .label("Phone"),
    password: Joi.string()
      .required()
      .max(50)
      .min(3)
      .label("Password"),
    active: Joi.boolean()
      .label("Active")
      .optional()
      .allow(null)
  };

  constructor(props) {
    super(props);
    this.state.website = props.website;
  }
  async componentDidMount() {

    if (this.props.recordToEdit) {

      this.setState({
        data: this.props.recordToEdit,
        recordToEdit: this.props.recordToEdit
      });
    } else {
      const { data } = this.state;
      data.companyId = this.props.companyId;

      this.setState({ data });
    }
  }

  doSubmit = async () => {

    let status = "";
    let message = "";

    const { data: agent } = this.state;

    if (agent.companyId === 0){
      var data_website = this.state.website;
      if( data_website === undefined )
        data_website = this.state;
      
      if(data_website !== undefined)
        agent.companyId = data_website.companyId;
    } 

    if (agent.userId > 0) {
      const resS = await agentService.update(agent);
      status = resS.status;
      message = resS.message;
    } else {
      const resF = await agentService.create(agent);
      status = resF.status;
      message = resF.message;
    }

    if (status) {
      toast.success(message);
      const { onClose } = this.props;
      onClose();
    } else {
      toast.error(message);

      const errors = { ...this.state.errors };
      errors.email = message;
      this.setState({ errors });
    }
  };

  doCancel = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const isEdit = this.state.recordToEdit != null;
    return (
      <React.Fragment>
        <div className="">
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                {this.renderInput("fullname", "Full name")}
              </div>
              <div className="col-md-6">
                {this.renderInput("email", "Email")}
              </div>
            </div>

            {!isEdit && 
            <div className="row">
            <div className="col-md-6" >
              {this.renderInput("username", "Username")}
            </div>
            <div className="col-md-6">
              {this.renderInput("password", "Password")}
            </div>
          </div>
          }

            <div className="row">
              <div className="col-md-6">
                {this.renderInput("phone", "Phone")}
              </div>
              <div className="col-md-6"></div>
            </div>

            <div className="row">
              <div className="col-md-6">
                {this.renderCheckbox("active", "Active", "big-checkbox")}
              </div>
              <div className="col-md-6"></div>
            </div>

            <div className="row">
              <div className="col-md-8">
                <div className="form-group">
                  {this.renderButton(
                    "Save",
                    "btn btn-primary",
                    this.handleSubmit,
                    "submit",
                    true
                  )}
                  <span style={{ minWidth: "30px" }}>&nbsp;</span>
                  {this.renderButton("Cancel", "btn", this.doCancel)}
                </div>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default AgentForm;
