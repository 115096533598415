import jwtDecode from "jwt-decode";
import http from "./httpService";
//import config from "../config.json"; 
import ApiHelper from "Utils/apiHelper";
import Logger from "./logService";
import httpService from "./httpService";

const tokenKey = "token";
//const apiUrl = process.env.REACT_APP_API_URL;


export async function token({ username, password }) {
  const url = "/auth/token";
  const postData = { username, password };

  try {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json"
    };

    http.defaults.headers.common = headers;
    const { data: token } = await http.post(url, postData);
    //const data = response[1];
    // alert("login complete");
    Logger.info("JWT Token acquired:" + token);
    return token;
  } catch (ex) {
    if (ex.response && ex.response.status === 404) {
      const msg = "expected error occurred while receiving JWT token";
      return ApiHelper.badResponse(null, false, msg);
    }
    //undo UI changes if needed
  }
}

export async function login(customer) {
  const url = "/auth/login";
  const postData = customer;

  try {
    const { data, headers } = await http.post(url, postData);

    //extract token and save it to local storage
    if (data) {
      const token = headers["x-auth-token"];
      if (token) loginWithJwt(token);
    }
    return { data };
  } catch (ex) {
    let msg = "Server Error";
    if (ex.response && ex.response.status === 404) {
      msg = "No response from the server";
    }
    return ApiHelper.badResponse(null, false, msg);
  }
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
  http.SetTokenInAuthHeader(token);
}

export function logout() {
  localStorage.removeItem(tokenKey);
  httpService.RemoveTokenFromAuthHeader();
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export default {
  token,
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt
};
