import React, { Component } from 'react';

class PaymentOptions extends Component {
    constructor(props) {
        super(props);
        this.state = { selectPayPal: false }
    }

    PayPalSelect = (e) => {
        this.props.PaypalSelected();
        return true;
    }

    CreditCardSelect = (e) => {
        this.props.CreditCardSelected();
        return true;
    }
    render() { 
        return ( 
        <React.Fragment>
            <hr className="mb-4" />
            <h4 className="mb-3">Payment</h4>

            <div className="d-block my-3">
                <div className="custom-control custom-radio">
                    <input id="credit" 
                    name="paymentMethod" 
                    type="radio" 
                    onChange={this.CreditCardSelect}
                    className="custom-control-input" 
                    required 
                    />
                    <label className="custom-control-label" htmlFor="credit">Credit card</label>
                </div>
                <div className="custom-control custom-radio">
                    <input id="paypal"
                     name="paymentMethod"
                     value="paypal"
                      type="radio"
                        onChange={this.PayPalSelect}
                      className="custom-control-input" 
                      required 
                     />
                    <label className="custom-control-label" htmlFor="paypal">Paypal</label>
                </div>
            </div>
        </React.Fragment> );
    }
}
 
export default PaymentOptions;