import http from "./httpService";
import ApiHelper from "Utils/apiHelper";
import Logger from "./logService";
export async function getAll() {
  const { data: response } = await http
    .get("/websites")
    .then(res => {
      return res;
    })
    .catch(err => {
      Logger.error(err);
    });

  return response;
}

export async function get(id) {
  const { data: response } = await http
    .get(`/websites/${id}`)
    .then(res => {
      return res;
    })
    .catch(err => {
      Logger.error(err);
      return ApiHelper.badResponse(null, false, err);
    });

  return response;
}
export async function create(website) {
  const url = "/websites";

  const postData = website;

  try {
    const { data: response } = await http.post(url, postData);
    if (response) return response;

    throw new Error("No response from server");
  } catch (ex) {
    let msg = "Server Error";
    Logger.error(ex);
    if (ex.response && ex.response.status === 404) {
      msg = "Errors while creating of new website";
    }
    return ApiHelper.badResponse(null, false, msg);
  }
}

export async function update(website) {
  const url = "/websites";

  const postData = website;

  try {
    const { data: response } = await http.put(url, postData);

    if (response) return response;

    throw new Error("No response from server");
  } catch (ex) {
    let msg = "Server Error";
    if (ex.response && ex.response.status === 404) {
      msg = "Errors while updating website information." + ex.response;
    }
    Logger.error(ex);
    return ApiHelper.badResponse(null, false, msg);
  }
}

export async function updateSchedule(website) {
  const url = "/websites/schedule";

  const postData = website;

  try {
    const { data: response } = await http.put(url, postData);

    if (response) return response;

    throw new Error("No response from server");
  } catch (ex) {
    let msg = "Server Error";
    if (ex.response && ex.response.status === 404) {
      msg = "Errors while updating website information." + ex.response;
    }
    Logger.error(ex);
    return ApiHelper.badResponse(null, false, msg);
  }
}

export async function remove(id) {
  const url = `/websites/${id}`;

  const { data: response } = await http
    .delete(url)
    .then(res => {
      return res;
    })
    .catch(err => {
      Logger.error(err);
      return ApiHelper.badResponse(null, false, err);
    });

  return response;
}

export async function getCustomerWebsites(customerId) {
  const { data: response } = await http
    .get(`/websites/customer/${customerId}`)
    .then(res => {
      return res;
    })
    .catch(err => {
      Logger.error(err);
    });

  return response;
}

export default {
  getAll,
  get,
  create,
  update,
  updateSchedule,
  remove,
  getCustomerWebsites
};
