import React from "react";
import { Redirect } from "react-router-dom";
import Joi from "joi-browser";
import Form from "Components/Common/form";
import auth from "Services/authService";
import Logger from "Services/logService";
import { Link } from "react-router-dom";

class Login extends Form {
  state = {
    data: { username: "", password: "" },
    errors: {}
  };

  validationSchema = {
    username: Joi.string()
      .required()
      .label("Username"),
    password: Joi.string()
      .required()
      .label("Password")
  };

  async componentDidMount() {
    // const { data } = await customerService.getAll();
    // Logger.info(data);
  }

  doSubmit = async () => {
    const { data } = await auth.login(this.state.data);

    //Logger.info(data);
    if (data.status) {
      Logger.success(data.message);
      //this.props.history.push("/admin");
      const { state } = this.props.location;
      window.location = state ? state.from.pathname : "/portal";
    } else {
      Logger.log(data.message);
      const errors = { ...this.state.errors };
      errors.username = data.message;
      this.setState({ errors });
      //Logger.error(data.message);
    }
  };

  doCancel = () => {
    
  };

  render() {
    if (auth.getCurrentUser()) return <Redirect to="/" />;

    //const { data, errors } = this.state;
    return (
      <header className="content-header">
        <div className="container-fluid">
          <div className="row justify-content-center align-items-center"  style={{height: 60+'vh'}}>
            <div
              className="col-xl-auto text-black fadeIn"
              style={{ display: "inline-block" }}
            >
              <div className="mt-5">
                <br />
                <h2>Live Chat Login</h2>
                <br />
                <br />
                <div className="login-form">
                  <form onSubmit={this.handleSubmit}>
                    {this.renderInput("username", "Email")}
                    {this.renderInput("password", "Password", "password")}

                    <div className="form-group">
                      {this.renderButton(
                        "Login",
                        "btn btn-primary",
                        this.handleSubmit,
                        "submit",
                        true
                      )}
                      <span style={{ minWidth: "30px" }}>&nbsp;</span>
                      <Link
                        to="/home/forgot"
                        className="mr-2 mb-2 page-scroll">
                        Forgotten password?
                      </Link>
                      
                    </div>
                  </form>
                </div>
                <div>
                  
                </div>
              </div>

              <p className="mt-5" align="center" />
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Login;
