import http from "./httpService";
//import config from "../config.json";
import ApiHelper from "Utils/apiHelper";
import auth from "./authService";
import Logger from "./logService";
import apiHelper from "Utils/apiHelper";
export async function getAll() {
  // return http
  //   .get(config.apiUrls.customersApi)
  //   .then(res => {
  //     return res.data;
  //   })
  //   .catch(err => {
  //     console.error(err);
  //   });
  const { data: response } = await http
    .get("/customers")
    .then(res => {
      return res;
    })
    .catch(err => {
      Logger.error(err);
    });

  return response;
}

export async function get(id) {
  const { data: response } = await http.get(`/customers/${id}`);
  return response;
}

export async function forgotPassword(email) {
  const { data: response } = await http.get(`/customers/forgot/${email}`);
  return response;
}

export async function resetPassword(resetSecret, newPassword) {
  let postData = { 
    verificationToken: resetSecret 
    , password: newPassword
  };

  const { data: response } = await http.post(`/customers/resetPassword`, postData);
  return response;
}

export async function register(customer) {
  const url = "/customers";

  customer.customerName = customer.firstName + " " + customer.lastName;
  customer.username = customer.email;
  customer.phone1 = customer.phone;

  const postData = customer;

  try {
    const { data, headers } = await http.post(url, postData);
    if (data) {
      const token = headers["x-auth-token"];
      auth.loginWithJwt(token);
    }
    return { data };
  } catch (ex) {
    let msg = "Server Error";
    if (ex.response && ex.response.status === 404) {
      msg = "Errors while registration of new customer";
    }
    return ApiHelper.badResponse(null, false, msg);
  }
}

export async function create(customer) {
  const url = "/customers";

  if (customer.firstName && customer.lastName)
    customer.customerName = customer.firstName + " " + customer.lastName;

  customer.username = customer.email;

  const postData = customer;

  try {
    const { data } = await http.post(url, postData);
    if (data) {
      return { data };
    }
  } catch (ex) {
    let msg = "Server Error";
    if (ex.response && ex.response.status === 404) {
      msg = "Errors while creating new customer";
    }
    return ApiHelper.badResponse(null, false, msg);
  }
}
export async function update(customer) {
  const url = "/customers";

  if (customer.firstName && customer.lastName) {
    customer.customerName = customer.firstName + " " + customer.lastName;
  }
  if (customer.email) customer.username = customer.email;

  if (customer.phone) customer.phone1 = customer.phone;

  const postData = customer;

  try {
    const { data } = await http.put(url, postData);
    if (data) {
      // const token = headers["x-auth-token"];
      // if (token) auth.loginWithJwt(token);
    }
    return { data };
  } catch (ex) {
    let msg = "Server Error";
    if (ex.response && ex.response.status === 404) {
      msg = "Errors while updating customer information." + ex.response;
    }
    return ApiHelper.badResponse(null, false, msg);
  }
}

export async function updateInfo(customer) {
  const url = "/customers/info";

  if (customer.firstName && customer.lastName) {
    customer.customerName = customer.firstName + " " + customer.lastName;
  }
  if (customer.email) customer.username = customer.email;

  if (customer.phone) customer.phone1 = customer.phone;

  const postData = customer;

  try {
    const { data } = await http.put(url, postData);
    if (data) {
      // const token = headers["x-auth-token"];
      // if (token) auth.loginWithJwt(token);
    }
    return { data };
  } catch (ex) {
    let msg = "Server Error";
    if (ex.response && ex.response.status === 404) {
      msg = "Errors while updating customer information." + ex.response;
    }
    return ApiHelper.badResponse(null, false, msg);
  }
}

export async function remove(id) {
  const url = `/customers/${id}`;

  const { data: response } = await http
    .delete(url)
    .then(res => {
      return res;
    })
    .catch(err => {
      Logger.error(err);
    });

  return response;
}

async function getSubscriptionTypes() {
  const url = "/customers/subscriptionTypes";
  const { data: response } = await http
    .get(url)
    .then(res => {
      return res;
    })
    .catch(err => {
      Logger.error(err);
      return apiHelper.badResponse([], false, "no response from server");
    });

  return response;
}

export async function changePassword(customerId, password, newPassword1, newPassword2) {
  const url = "/customers/changePassword";

  const postData = { customerId: customerId, password: password, newPassword: newPassword1, newPassword2: newPassword2};

  try {
    const { data } = await http.post(url, postData);
    if (data) {
      return { data };
    }
  } catch (ex) {
    let msg = "Server Error";
    if (ex.response && ex.response.status === 404) {
      msg = "Errors while changing password";
    }
    return ApiHelper.badResponse(null, false, msg);
  }
}

export default {
  getAll,
  get,
  register,
  create,
  update,
  updateInfo,
  remove,
  getSubscriptionTypes,
  forgotPassword,
  resetPassword,
  changePassword
};
