import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ProfileForm from 'Portal/Admin/Components/profile-form';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import AccountTable from 'Portal/Admin/Components/account-table';
import websiteService from 'Services/websiteService';
import { toast } from 'react-toastify';
import ScheduleWebsiteForm from 'Portal/Admin/Components/schedule-website-form';

class CustomerProfileSection extends Component {
    constructor(props) {
        super(props);
  
        this.state = { 
        };
    }
    render() { 
        let {customer} = this.props;
        
        if(customer !== undefined)
        { 
            customer.firstPhone = customer.phone1; 
            customer.secondPhone = customer.phone2;
        }
                
        return (<React.Fragment>
            <ProfileForm recordToEdit={this.props.customer} />
        </React.Fragment> );
    }
}

class CustomerAccountSection extends Component {
    constructor(props) {
        super(props);
  
        this.state = { 
            show: false,
            selectedWebsite: { title: ""} ,
            companies: []
        };
    }
    async loadWebsites() {
        if(this.props.customer === undefined) return [];

        const { data, status, message } = await websiteService.getCustomerWebsites(this.props.customer.customerId);
    
        if (!status) toast.error(message);
        return data;
    }
    
    
    async componentDidMount(){
        const websites = await this.loadWebsites();
        this.setState({companies:websites});
    }

    handleSchedule=(website) => {
        console.log(website);
        this.setState({show: true, selectedWebsite: website});
    }
    handleClose = () => {
        this.setState({show: false});
    }
    handleUpdateClose = async (status) => {
        if(status){
            const websites = await this.loadWebsites();
            this.setState({companies:websites});    
        }
    }
    handleScheduleSubmit = async (e) => {
        console.log("handle submit: ",e);
        let status = "";
        let message = "";
        e.companyId = this.state.selectedWebsite.companyId;

        if (e.companyId > 0) {
          const resS = await websiteService.updateSchedule(e);
          console.log(resS);
          status = resS.status;
          message = resS.message;
        }
    
        if (status) {
          toast.success(message);
          this.setState({show: false});
          const websites = await this.loadWebsites();
          this.setState({companies:websites});
        } else {
          toast.error(message);
        }
    };
    render() { 
        return (<React.Fragment>
            <Container>
                <AccountTable data={this.state.companies} onSchedule={this.handleSchedule} />
                <Row>
                    <Col>
                        <Link to="/portal/customers/websites" className="collapse-item">
                            Go to Websites
                        </Link>
                    </Col>
                </Row>
                <Modal show={this.state.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Schedule {this.state.selectedWebsite.title}</Modal.Title> 
                </Modal.Header>
                <Modal.Body>
                    <ScheduleWebsiteForm record={this.state.selectedWebsite} onSubmit={this.handleScheduleSubmit} />
                </Modal.Body>
            </Modal>
            </Container>
        </React.Fragment>);
    }
}

export {CustomerProfileSection , CustomerAccountSection};