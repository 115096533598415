import React, { Component } from 'react';
import {Modal, Form, Container, Row, Col, Button } from 'react-bootstrap';
import custService from 'Services/customerService';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            password1: "",
            password2: "",
            code: "",
            showConfirmation: false
        };
    }
    componentDidMount () 
    {
        const queryString = require("query-string");
        const parsed = queryString.parse(this.props.location.search);
        this.setState({code: parsed.code});
    }

    handleConfirmationClose = () => 
    {
        this.setState({showConfirmation: false});
        window.location = "/home/login";    
    }

    handleChange = (event) => {
        let n = event.target.name;
        let v = event.target.value;
        this.setState({ [n]:v });
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        var status = true;
        var messsage = "";

        if(this.state.password1 === "")
        {
            status = false;
            messsage = "Enter password";
        }

        if(status && this.state.password2 === "")
        {
            status = false;
            messsage = "Enter password";
        }

        if(status && this.state.password1 !== this.state.password2)
        {
            status = false;
            messsage = "Password doesn't match";
        }

        if(status)
        {
            const { data } = await custService.resetPassword(this.state.code, this.state.password1);
            if(data)
                this.setState({showConfirmation: true});
        }
        else
        {
            alert(messsage);
        }
    }

    render() { 
        return ( 
        <React.Fragment>
            <header className="content-header text-black">
                <Container fluid={true} className="mt-5">
                    <Row style={{height: 60+'vh'}} className="justify-content-center align-items-center">
                        <Col md="auto">
                            <Row className="justify-content-md-center">
                                <Col md="auto">
                                    <h2>Please set a new password</h2>
                                    <br />
                                </Col>
                            </Row>
                            <Row className="justify-content-md-center">
                                <Col md="auto">
                                    <Form onSubmit={this.handleSubmit}>
                                        <Form.Group>
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control required name="password1" type="password" placeholder="Enter password" onChange={this.handleChange} />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Confirm password</Form.Label>
                                            <Form.Control required name="password2" type="password" placeholder="Confirm password" onChange={this.handleChange} />
                                        </Form.Group>
                                        <Button variant="primary" type="submit">Confirm</Button>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </header>
            <Modal 
                show={this.state.showConfirmation} 
                onHide={this.handleConfirmationClose}
                dialogClassName="modal-90w"
                centered 
                >
                <Modal.Header closeButton>
                <Modal.Title>Successful!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <p>Your password has been updated successfully</p>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={this.handleConfirmationClose}>Close</Button>
                </Modal.Footer>
            </Modal>

        </React.Fragment> 
        );
    }
}
 
export default ResetPassword;