//import _ from "lodash";
import queryString from "query-string";

export function filterProperty(items, searchKeyword, property) {
  let filtered = items.filter(
    d =>
      d[property] &&
      d[property].toLowerCase().indexOf(searchKeyword.toLowerCase()) !== -1
  );
  return filtered;
}

export function filterProperties(items, searchKeyword, properties) {
  let filtered = items;
  properties.forEach(property => {
    //console.log("forEach:", property, items[property]);

    filtered = filtered.filter(
      d =>
        d[property] &&
        d[property].toLowerCase().indexOf(searchKeyword.toLowerCase()) !== -1
    );

    console.log(filtered);
  });

  return filtered;
}

export function parseQueryString(props) {
  const parsed = queryString.parse(props.location.search);
  return parsed;
}
