import React, { Component } from 'react';
import { Container, Row , Col , Form } from 'react-bootstrap';
import Moment from 'react-moment';
import style from 'Template/css/chat.module.css';

class ChatView extends Component {

    render() { 
        
        const data = this.props.data;
        const languages = this.props.languages;
        const selectedLang = "en-US";
        return (<React.Fragment>
            <Container>
                <Row className={style.languageArea}>
                    <Col>
                        <Form.Control as="select" onChange={this.props.onLanguageChange}>
                            {languages.map( lang => {
                            if(lang.id === selectedLang)
                                return (<option value={lang.id} selected>{lang.title}</option>);
                            else
                                return (<option value={lang.id}>{lang.title}</option>);
                            })}
                        </Form.Control>
                    </Col>
                </Row>
            {data.map(item => {
                if(item.sender === this.props.agentId)
                    return <SelfChat item={item} />
                else
                    return <OtherChat item={item} />
            })}
            </Container>
        </React.Fragment>);
    }
}
function OtherChat(props){
    const item = props.item;
    return (
        <Row>
            <Col md="auto">
                <Row>
                    <Col className={style.chatSender}>{item.senderUsername}</Col>
                </Row>
                <Row>
                    <Col md="auto" className="rounded border border-primary">{item.messageText}</Col>                    
                    <Col md="auto"><img alt={item.senderFlag} className={style.chatLanguageImage} src={"/flags/"+item.senderFlag+".png"} /></Col>
                    <Col md="auto" className={style.chatDate}><Moment fromNow>{item.timestamp}</Moment></Col>
                </Row>
            </Col>
        </Row>
    );
}
function SelfChat(props){
    const item = props.item;
    return (
        <Row className="justify-content-end">
            <Col md="auto">
                <Row>
                    <Col className={style.chatSender}>{item.senderUsername}</Col>
                </Row>
                <Row className="justify-content-end">
                    <Col md="auto" className="rounded border border-danger">{item.messageText}</Col>                    
                    <Col md="auto"><img alt={item.senderFlag} className={style.chatLanguageImage} src={"/flags/"+item.senderFlag+".png"} /></Col>
                    <Col md="auto" className={style.chatDate}><Moment fromNow>{item.timestamp}</Moment></Col>
                </Row>
            </Col>
        </Row>
    );
}
export default ChatView;