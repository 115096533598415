import http from "./httpService";
import ApiHelper from "Utils/apiHelper";
import Logger from "./logService";
export async function getCustomerChats(customerId, websiteId) {
  const { data: response } = await http
    .get(`/chats/customer/${customerId}/website/${websiteId}`)
    .then(res => {
      return res;
    })
    .catch(err => {
      Logger.error(err);
    });

  return response;
}

export async function getAgentChats(agentId) {
  const { data: response } = await http
    .get(`/chats/agent/${agentId}`)
    .then(res => {
      return res;
    })
    .catch(err => {
      Logger.error(err);
    });

  return response;
}

export async function getChat(groupId,language="default") {
  const { data: response } = await http
    .get(`/chats/group/${groupId}/${language}`)
    .then(res => {
      return res;
    })
    .catch(err => {
      Logger.error(err);
      return ApiHelper.badResponse(null, false, err);
    });

  return response;
}

export async function getLanguages() {
  const { data: response } = await http
    .get(`/chats/languages`)
    .then(res => {
      return res;
    })
    .catch(err => {
      Logger.error(err);
      return ApiHelper.badResponse(null, false, err);
    });

  return response;
}

export async function remove(id) {
  const url = `/chat/${id}`;

  const { data: response } = await http
    .delete(url)
    .then(res => {
      return res;
    })
    .catch(err => {
      Logger.error(err);
      return ApiHelper.badResponse(null, false, err);
    });

  return response;
}

export default {
  getCustomerChats,
  getAgentChats,
  getChat,
  getLanguages,
  remove
};
