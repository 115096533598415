import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import PortalForm from 'Components/Common/form';
import Joi from "joi-browser";
import PaymentService from 'Services/paymentService';
import { toast } from 'react-toastify';
import * as countries from './data/countries.json'
import _ from "lodash";

class PinPaymentsInfo extends PortalForm {
    state = {  
        data : {
            nameOnCard: "",
            cardNumber: "",
            expiryMonth: "",
            expiryYear: "",
            cvc: "",
            firstName: "",
            lastName: "",
            email: "",
            address1: "",
            address2: "",
            zip: "",
            description: "",
            country: "",
            state: "",
            city: ""
        },
        errors: {},
        countries: [],
        states: [],
        cities: [],
    }
    
    all = []
    
    async componentDidMount(){
        this.all = countries.default;
        this.all.splice(0,0,{id: 0, name: "Select Country"});
        this.setState({ countries: countries.default });
    }
    setCountryStates = (country_id, load_first_state = false) => {
        const country = _.find(this.state.countries, (o) => +o.id === country_id);
        var states = _.keys(country.states);
        states = _.map( states , (o) => { return {label: o}; });
        var data = { ...this.state.data };
        data.country = country_id;
        this.setState({ states : states , data: data });

        if ( load_first_state && states.length > 0 ){
           this.setStateCities(states[0], country_id, true);
        }
    }
    setStateCities = (country_state, country_id, load_first_city = false ) => {
        const country = _.find(this.state.countries, (o) => +o.id === +country_id);
        var cities = country.states[country_state];
        cities = _.map(cities , (o) => { return {label: o}; } );
        var data = { ...this.state.data };
        data.state = country_state;
        this.setState({ cities : cities , data: data });
    }
    setCity = (city) => {
        var data = { ...this.state.data };
        data.city = city;
        this.setState({ data: data });
    }
    onCountryChange  = (e) => {
        var sel_country = +e.currentTarget.value;
        this.setCountryStates(sel_country, true);
        this.handleChange(e);
        return true;
    }
    onStateChange = (e) => {
        this.setStateCities(e.currentTarget.value, this.state.data.country);
        this.handleChange(e);
        return true;
    }
    validationSchema = {
        nameOnCard: Joi.string()
          .min(3)
          .max(30)
          .required()
          .label("Name on card"),
        cardNumber: Joi.string()
          .creditCard()
          .required()
          .label("Credit card number"),
        expiryMonth: Joi.number()
          .min(1)
          .max(12)
          .required()
          .label("Month")
          ,
        expiryYear: Joi.number()
          .min(0)
          .required()
          .label("Year")
          ,
        cvc: Joi.number()
          .min(100)
          .max(999)
          .required()
          .label("CVC"),
        firstName: Joi.string()
          .min(3)
          .max(30)
          .required()
          .label("First Name"),
        lastName: Joi.string()
          .min(3)
          .max(30)
          .required()
          .label("Last Name"),
        email: Joi.string()
          .email()
          .required()
          .label("Email"),    
        address1: Joi.string()
            .required()
            .label("Address 1"),
        address2: Joi.string()
            .allow('')
            .label("Address 2"),
        zip: Joi.string()
            .allow('')
            .label("Zip"),
        description: Joi.string()
            .required()
            .label("Description"),
        country: Joi.string()
            .required()
            .label("Country"),
        city: Joi.string()
            .required()
            .label("City"),
        state: Joi.string()
            .required()
            .label("State")
      };

    doSubmit = async () => {
        var fullname = this.state.data.firstName + " " + this.state.data.lastName;
        var country = _.find(this.state.countries, (o) => +o.id === +this.state.data.country);
        const data  = await PaymentService.requestPin({
            Email: this.state.data.email,
            Description: this.state.data.description,
            Currency: this.state.data.currency,
            Amount: this.props.amount,
            PinCard : 
            {
                CardNumber: this.state.data.cardNumber,
                CVC: this.state.data.cvc,
                ExpiryMonth: this.state.data.expiryMonth,
                ExpiryYear: this.state.data.expiryYear,
                Name: fullname,
                Address1: this.state.data.address1,
                Address2: this.state.data.address2,
                City: this.state.data.city,
                Postcode: this.state.data.zip,
                State: this.state.data.state,
                Country: country.name
            }
         });
    
        
        if (data.isError) {
            toast.error(data.description);
        } else {
            toast.success(data.description);
        }
    };
    render() { 
        return ( 
            <React.Fragment>
                <Form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col md={3}>{this.renderInput("nameOnCard","Name On Card","text","John Smith")}</Col>
                        <Col md={6}>{this.renderInput("cardNumber","Card Number","text","42xxxxxxxxxxxxxx")}</Col>
                    </Row>
                    <Row>
                        <Col md={1}>{this.renderInput("expiryMonth", "Expiry Month", "text"," 12")}</Col>
                        <Col md={1}>{this.renderInput("expiryYear", "Expiry Year", "text", "21")}</Col>
                        <Col md={1}>{this.renderInput("cvc", "CVC", "text", "123")}</Col>
                    </Row>
                    <Row>
                        <Col><hr className="mb-4" /></Col>
                    </Row>
                    <Row>
                        <Col md={3}>{this.renderInput("firstName","First Name", "text", "John")}</Col>
                        <Col md={3}>{this.renderInput("lastName","Last Name", "text", "Smith")}</Col>
                    </Row>
                    <Row>
                        <Col>{this.renderInput("email","Email","text", "you@example.com")}</Col>
                    </Row>
                    <Row>
                        <Col>{this.renderInput("address1","Address 1")}</Col>
                    </Row>
                    <Row>
                        <Col>{this.renderInput("address2","Address 2")}</Col>
                    </Row>
                    <Row>
                        <Col md={3}>{this.renderSelect(
                                                "country",
                                                "Country",
                                                "name",
                                                "id",
                                                this.state.countries,
                                                this.state.data.country,
                                                this.onCountryChange
                                                )}</Col>
                        <Col md={3}>{this.renderInput(
                                                "city",
                                                "City")}</Col>
                    </Row>
                    <Row>
                        <Col md={3}>{this.renderSelect(
                                                "state",
                                                "State",
                                                "label",
                                                "label",
                                                this.state.states,
                                                this.state.data.state,
                                                this.onStateChange)}</Col>
                        <Col md={3}>{this.renderInput("zip","Zip")}</Col>
                    </Row>
                    <Row>
                        <Col>{this.renderInput("description", "Description", "text", "Any additional information")}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button varient="primary" type="submit">
                            Continue to Payment
                            </Button>
                        </Col>
                    </Row>
                </Form>
        </React.Fragment>
         );
    }
}
 
export default PinPaymentsInfo;