import React, { Component, Suspense } from "react";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import websiteService from "Services/websiteService";
import Pagination from "Components/Common/pagination";
import { toast } from "react-toastify";
import { paginate } from "Utils/paginate";
import ListGroup from "Components/Common/listGroup";
import Logger from "Services/logService";
import { PageHeading } from "../portalLayout";
import Loader from "Components/Common/loader";
import agentService from "Services/agentService";
import { parseQueryString } from "Utils/util";
import AgentForm from "./Components/agent-form";
import AgentTable from "./Components/agent-table";

class AdminAgents extends Component {
  state = {
    customerId: 0,
    websiteId: 0,
    agents: [],
    websites: [],
    selectedWebsite: {},
    currentPage: 1,
    pageSize: 10,
    sortColumn: { path: "title", order: "asc" },
    searchKeywords: "",
    subscriptionTypes: [],
    loading: true,
    recordToEdit: null,
    //cusModalIsOpen: false,
    agentModalIsOpen: false
  };

  setShow = (modalType, show) => {
    //if (modalType === "customer") this.setState({ cusModalIsOpen: show });
    if (modalType === "agent") this.setState({ agentModalIsOpen: show });
    //if (modalType === "chat") this.setState({ chatModalIsOpen: show });
  };

  openModal = modalType => {
    this.setShow(modalType, true);
  };
  closeModal = modalType => {
    this.setShow(modalType, false);

    this.setState({ recordToEdit: null });

    this.componentDidMount();
  };

  constructor(props) {
    super(props);

    const qParams = parseQueryString(this.props);
    this.state.searchKeywords = qParams.search;
    this.state.websiteId = qParams.websiteId;
    this.state.customerId = qParams.customerId;
  }

  async componentDidMount() {
    try {
      this.setState({ loading: true });
      const data = await this.loadAgents();
      const websites = await this.loadWebsites(this.state.customerId);

      this.setState({ agents: data, websites });

      if (this.state.websiteId > 0) {
        const selectedWebsite = websites.filter(
          w => w.companyId && w.companyId.toString() === this.state.websiteId
        )[0];
        this.setState({ selectedWebsite });
      }
    } catch (ex) {
      toast.error(ex.message);
    } finally {
      this.setState({ loading: false });
    }
  }

  async loadAgents() {
    const { data, status, message } =
      this.state.customerId > 0
        ? await agentService.getCustomerAgents(this.state.customerId)
        : await agentService.getAll();

    if (!status) toast.error(message);
    return data;
  }

  async loadWebsites(customerId) {
    const { data } =
      customerId > 0
        ? await websiteService.getCustomerWebsites(customerId)
        : await websiteService.getAll();

    const websites = [{ title: "All websites of this customer" }, ...data];
    return websites;
  }

  handleWebsiteSelect = website => {
    this.setState({ selectedWebsite: website, currentPage: 1 });
  };

  handleViewChats = agent => {
    this.setState({ recordToEdit: agent });

    this.props.history.push(`/portal/customers/chats?agentId=${agent.userId}`);
  };

  handleEdit = agent => {
    this.setState({ recordToEdit: agent });
    this.openModal("agent");
  };

  async updateAgent(agent) {
    //const originalData = this.state.websites;
    const { status } = await agentService.update(agent);

    if (status) {
      return true;
    } else {
      // this.setState({ websites: originalData });
      return false;
    }
  }

  handleDelete = async agent => {
    const originalData = this.state.agents;

    const agents = this.state.agents.filter(c => c.userId !== agent.userId);
    this.setState({ agents });

    const { message, status } = await agentService.remove(agent.userId);
    if (status) toast.success(message);
    else {
      Logger.error(message);
      this.setState({ websites: originalData });
    }
  };

  handleActivity = async agent => {
    //toast("Active link clicked");
    const originalAgents = [...this.state.agents];
    const agents = [...this.state.agents];
    const index = agents.indexOf(agent);
    agents[index] = { ...agents[index] };
    agents[index].active = !agents[index].active;

    await this.updateAgent(agents[index]).then(result => {

      if (result) {
        toast.success("Selected agent record updated");
        this.setState({ agents });
      } else {
        toast.error("Failed to update agent");
        this.setState({ agents: originalAgents });
      }
    });
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleSearch = event => {
    this.setState({ searchKeywords: event.target.value });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      agents: allAgents,
      searchKeywords
    } = this.state;

    let filtered = this.state.websiteId
      ? allAgents.filter(
          m => m.companyId && m.companyId.toString() === this.state.websiteId
        )
      : allAgents;

    filtered =
      this.state.selectedWebsite.companyId > 0
        ? filtered.filter(
            m =>
              m.companyId &&
              m.companyId === this.state.selectedWebsite.companyId
          )
        : filtered;

    filtered = searchKeywords
      ? filtered.filter(
          m =>
            (m.email &&
              m.email.toLowerCase().indexOf(searchKeywords.toLowerCase()) !==
                -1) ||
            (m.username &&
              m.username.toLowerCase().indexOf(searchKeywords.toLowerCase()) !==
                -1) ||
            (m.phone &&
              m.phone.toLowerCase().indexOf(searchKeywords.toLowerCase()) !==
                -1)
        )
      : filtered;

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const agents = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: agents };
  };

  render() {
    if (this.state.loading) return <Loader />;

    const { length: count } = this.state.agents;
    const {
      pageSize,
      currentPage,
      sortColumn,
      websites,
      selectedWebsite
    } = this.state;

    if (count === 0) return <p>There are no agents in the system</p>;

    const { totalCount, data: agents } = this.getPagedData();

    return (
      <React.Fragment>
        <PageHeading heading="Agents" />
        <div className="row">
          <div className="col-2">
            <ListGroup
              items={websites}
              textProperty="title"
              valueProperty="companyId"
              selectedItem={selectedWebsite}
              onItemSelect={this.handleWebsiteSelect}
            />
          </div>
          <div className="col-10">
            <div className="flex-row">
              <div className="flex-col"></div>
              <div className="flex-col-right">
                <input
                  value={this.state.searchKeywords}
                  name="txtSearch"
                  type="text"
                  onChange={this.handleSearch}
                ></input>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p>Showing {totalCount} website accounts.</p>
                <Suspense fallback={<div>loading...</div>}>
                  <AgentTable
                    agents={agents}
                    sortColumn={sortColumn}
                    onActive={this.handleActivity}
                    onDelete={this.handleDelete}
                    onSort={this.handleSort}
                    onEdit={this.handleEdit}
                    onViewChats={this.handleViewChats}
                  />
                </Suspense>
                <Pagination
                  itemsCount={totalCount}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  onPageChange={this.handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>

        <Modal
          size="lg"
          show={this.state.agentModalIsOpen}
          onHide={() => this.closeModal("agent")}
          dialogClassName="modal-dialog"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Agent</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AgentForm
              companyId="0"
              recordToEdit={this.state.recordToEdit}
              onClose={() => this.closeModal("agent")}
            />
          </Modal.Body>
        </Modal>
        {/* <Modal
          size="lg"
          show={this.state.chatsModalIsOpen}
          onHide={() => this.closeModal("agent")}
          dialogClassName="modal-dialog"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add new Agent</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AgentForm
              website={this.state.recordToEdit}
              recordToEdit={null}
              onClose={() => this.closeModal("agent")}
            />
          </Modal.Body>
        </Modal> */}
      </React.Fragment>
    );
  }
}

export default AdminAgents;
