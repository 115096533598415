import React, { Component } from 'react';
import PaymentTable from "./payment-table";
import { toast } from 'react-toastify';
import PaymentService from 'Services/paymentService';
 
class SubscriptionHistory extends Component {
  state = {  
    loading: false,
    payments: [],
    sortColumn: {}
  }

  async loadWebsites() {
    const { data, status, message } = await PaymentService.getAllPayments();

    if (!status) toast.error(message);
    return data;
  }
  
  async componentDidMount() {
    try {
      this.setState({ loading: true });
      const data = await this.loadWebsites();

      this.setState({ payments: data });
    } catch (ex) {
      toast.error(ex.message);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() { 
    return (
      <React.Fragment>
        <div><h3>Subscription History</h3></div>
        <PaymentTable 
            payments={this.state.payments}
            sortColumn={this.state.sortColumn}
        />
  
      </React.Fragment>
    );
  }
}
 


export default SubscriptionHistory;
